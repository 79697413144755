const global = {
    namespaced:true,
    state:{
        debtors:[],
        debtors_selected:[],
        debtorsFail: [],
        debtorsCorrect: [],
    },
    mutations:{
        setDebtorsFail: (state, payload) => {
            state.debtorsFail = payload;
        },
        debtorsCorrect: (state, payload) => {
            state.debtorsCorrect = payload;
        }
    },
    getters:{
        debtors: state => [...state.debtors],
        debtors_selected: state => [...state.debtors_selected],
        
    },
};

export default global;
<template>
  <div id="app">
    <v-app>
      <v-main>
        <v-row
          id="languageSection"
          :style="{
            background: $vuetify.theme.themes.dark.green.secondary,
            height: '50px',
          }"
          v-if="!$store.state.global.hideAppBar"
        >
          <v-col class="pl-5">
            <h2 style="color:white">{{$store.state.global.PageTitle}}</h2>
          </v-col>
          <v-col cols="2" class="justify-end">
            <v-select
              :no-data-text="$t('qrGenerator.productsTable.data_available')"
              dense
              dark
              :items="languagesArray"
              :item-text="languagesArray.text"
              :item-value="languagesArray.value"
              :label="languageSelectorLabel"
              @change="changeLanguage"
              v-model="languageSelector"
            ></v-select>
          </v-col>
        </v-row>
        <router-view></router-view>
      </v-main>
      <snackbar />
      <!-- <window/> -->
      <confirm ref="confirm"></confirm>
    </v-app>
  </div>
</template>
<script>
  
export default {
  name: "App",
    data: function () {
    return {
      DashboardTitle:"Dashboard",
      languageSelectorLabel: this.$t("app.languageSelector_field"),
      languageSelector: this.getLanguage,
      prevRoute: '',
    };
  },
  
  async created() {
    this.$store.state.User.languageChangePermission = false;
    // The idea of this, is to tell the parent (qrmodul.ch) any change in the url
    // and update the url in qrmodul.ch
    this.$store.watch(
      (state) => state.global.url,
      (value, old) => {
        window.parent.postMessage({ url: `${value}` }, "*");
      }
    );
  },

  computed: {
    getLanguage() {
      return this.$store.getters['User/getLanguage'].language;
    },
    languagesArray:{
      get(){
        return [...this.$store.state.global.selected_languages];
      },
      set(value){
        this.$store.state.global.selected_languages = [...value];
      }
    }
  },

  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    // this.$i18n.locale = 'de';
  },
  async beforeMount() {
    const { language } = this.$route.params;
    if (language) {
      this.$i18n.locale = language;
      this.$store.state.User.language = language;
      this.languageSelector = language;
      this.languagesArray.forEach((lang) => {
        lang.text = this.$t(`app.languages.${lang.value}`);
      });
      this.languageSelectorLabel = this.$t("app.languageSelector_field");
    }

    if(!language) {
      this.$i18n.locale = 'de';
      this.$store.state.User.language = 'de';
      this.languageSelector = 'de';
      this.languagesArray.forEach((lang) => {
        lang.text = this.$t(`app.languages.${lang.value}`);
      });
      this.languageSelectorLabel = this.$t("app.languageSelector_field");
    }
  },
  watch: {
    $route(to, from) {
      const { language } = to.params;
      if (language) {
        this.$i18n.locale = language;
        this.$store.state.User.language = language;
        this.languageSelector = language;
        this.languagesArray.forEach((lang) => {
          lang.text = this.$t(`app.languages.${lang.value}`);
        });
        this.languageSelectorLabel = this.$t("app.languageSelector_field");
      }
    }
  },
  beforeRouteEnter( to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    
    async changeLanguage() {
      this.$i18n.locale = this.languageSelector;
      this.$store.state.User.language = this.languageSelector;
      this.languageSelectorLabel = this.$t("app.languageSelector_field");

      const { params, path } = this.$route;
      params.language = this.$i18n.locale;
      if( path !== this.prevRoute.path ) {
        if(path === '/') {
          this.$router.push({ params, path: `/${this.$i18n.locale}` });
        } else {
          this.$router.push({ params })
        }
      }
      this.languagesArray.forEach((language) => {
        language.text = this.$t(`app.languages.${language.value}`);
      });
    },
  },
};
</script>

<style>
#app {
  /* font-family: 'Open Sans'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  width: 100%;
  background: #f8f7f2;
  color: #000;
  /* padding: 10px; */
}

header ul {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}

header ul li a {
  color: #000;
  text-decoration: none;
}

li.get-started {
  background: #109fde;
  padding: 10px;
  /* color: #fff; */
}

li.get-started a {
  color: #fff;
}

header .container {
  display: flex;
  align-items: center;
}

ul.logo {
  width: 20%;
}

ul.links {
  width: 80%;
}

.logo {
  width: 60px;
}

.logo img {
  width: 100%;
}
</style>

<template>
  <v-form ref="form">
      <v-card-title>
        {{ $t("qrGenerator.productsTable.productsSelector_tab.tab2.main_title") }}
        <v-icon>mdi-file-pdf-outline</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <p>{{ $t("qrGenerator.productsTable.label_main_title") }}</p>
          </v-col>
          <v-col cols="4" class="pt-0">
              <v-select
                :no-data-text="$t('qrGenerator.productsTable.data_available')"
                 :hint="InfoText"
                 :label="$t('qrGenerator.productsTable.placement_title')"
                 :items="$t('qrGenerator.productsTable.placement.items')"
                 item-text="text"
                 item-value="value"
                 :class="{ disabled:bothCurrencies }"
                 v-model="placementSelected"
                 dense
             ></v-select>   
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              :hint="$t('qrGenerator.info')"
              v-model="pdfFile"
              :rules="[
                rules.PDF.isPDF,
                rules.PDF['2MB']
              ]"
              @change="fileChecker"
              :label="$t('qrGenerator.productsTable.file_input')"
              outlined
              dense
            ></v-file-input>
          </v-col>
        </v-row>

      </v-card-text>
  </v-form>
</template>

<script>
import userMixin from '@/mixins/user.mixin';

export default {
  mixins: [userMixin],
  data() {
    return {
      placementSelected:'NP',
      // placementArray:[
      //   {text:'Last page',value:'LP'},
      //   {text:'New page',value:'NP'},
      // ],
      rules: {
        PDF: {
          isEmpty: v => !!v ||  this.$t("qrGenerator.productsTable.message_title"),
          isPDF: v => {
            if (v !== null && v !== undefined) {
              return v.type == "application/pdf" ||  this.$t("qrGenerator.productsTable.message_title");
            } else {
              return true;
            }
          },
          "2MB": v => {
            if (v !== null && v !== undefined) {
              return v.size <= 2000000 || this.$t("Globals.recomended_size");
            } else return true;
          }
        }
      },
      tab: "tab-1",
      pdfFile: null
    };
  },
  computed:{
    InfoText(){
      if(this.placementSelected == 'LP')
          {
            return this.$t("qrGenerator.productsTable.info_text")
          }
          else
          {
            return "";
          }
    },
    bothCurrencies(){
        if(this.$store.state.QR.bothCurrencies)
           this.placementSelected="NP";
       return this.$store.state.QR.bothCurrencies;
    }
  },
  methods: {
    fileChecker(file) {
      //Checks whether the uploaded file is type pdf or not
      //if the file is not the required type, disables the generate button
      if (file !== null && file !== undefined) {
        if (file.type === "application/pdf" && file.size <= 2000000) {
          return (this.filePermission = true);
        } else {
          return (this.filePermission = false);
        }
      } else {
        return (this.filePermission = false);
      }
    }
  }
};
</script>

<style>

 .disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: white;
      border-color: #d1dbe5;   
  }
    
</style>

<template>
    <v-form ref="form" :disabled="show">   
        <v-card-text class="pt-0">
                <v-row>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.firstName_field')"
                            v-model="debitor.name"
                            :rules="[nameRequired,rules.debitorFullName, rules.noInvalidCharacters]"
                            counter="70"
                            :counter-value="() => this.debitor.name.length + this.debitor.last_name.length"
                            :maxLength="(70 - this.debitor.last_name.length)"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.lastName_field')"
                            v-model="debitor.last_name"
                            :rules="[rules.debitorFullName, rules.noInvalidCharacters]"
                            counter="70"
                            :counter-value="() => (this.debitor.name.length + this.debitor.last_name.length)"
                            :maxLength="(70 - this.debitor.name.length)"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="3" class="py-0">
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.street_field')"
                            col="8"
                            v-model="debitor.address.street"
                            :rules="[streetRequired, rules.address, rules.noInvalidCharacters]"
                            @input="debitorAddressType"
                            counter="60"
                            maxLength="60"
                        ></v-text-field>
                    </v-col>
                    <v-col col="2" class="py-0">
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.houseNumber_field')"
                            col="4"
                            v-model="debitor.address.number"
                            counter="16"
                            maxLength="16"
                            @input="debitorAddressType"
                            :rules="[rules.noInvalidCharacters]"
                        ></v-text-field>
                        <!-- debitor.numberCounter -->
                    </v-col>
                    <v-col col="2" class="py-0">
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.postalCode_field')"
                            v-model="debitor.address.zip"
                            :rules="[zipRequired,rules.zip, rules.nameMissingZip, rules.noInvalidCharacters]"
                            counter="16"
                            maxLength="16"
                            @input="debitorAddressType"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="6" class="py-0">
                        <v-text-field
                        :label="$t('qrGenerator.payableBy.city_field')"
                        v-model="debitor.address.city"
                        :rules="[cityRequired,rules.city, rules.nameMissingCity, rules.noInvalidCharacters]"
                        @input="debitorAddressType"
                        counter="30"
                        maxLength="30"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-select
                        :no-data-text="$t('qrGenerator.productsTable.data_available')"
                        :items="this.$t('countryList')"
                        item-text="name"
                        item-value="code"
                        :label="$t('qrGenerator.payableBy.country_field')"
                        v-model="debitor.address.country"
                        :rules="[countryRequired,rules.debitorCountryRules, rules.noInvalidCharacters]"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pa-0">
                        <v-card-title>{{ $t("qrGenerator.payableBy.currency_title") }}</v-card-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0" >
                        <v-select
                            :items="getCurrencies"
                            item-text="currency"
                            item-value="code"
                            :label="$t('qrGenerator.payableBy.currency_field')"
                            v-model="currency"
                            :class="{ disabled: wirActive }"
                            :value="e => $event.target.value"
                            :rules="[(v) => !!v || 'You have to select a currency',currencyRequired]"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0 d-flex justify-center align-center">
                        <v-checkbox v-model="amountStatus"></v-checkbox>
                        <p>{{ $t("qrGenerator.payableBy.disableAmount_field") }}</p>
                    </v-col>
                    <v-col cols="8" class="py-0">
                        
                        <v-text-field
                            v-if="showFieldWirPercentage"
                            label="WIR in %"
                            type="number"
                            v-model="percentageWir" 
                            v-on:input="calculatePercentageAmount" 
                            v-on:change="formatWirPercentage"
                            :rules="[rules.percentage, rules.noInvalidCharacters]"
                        ></v-text-field>

                        <v-row v-if="showAmounts">
                             <v-col cols="4">
                                 <v-text-field
                                  :label="$t('Debtors.amount_CHF')"
                                  :disabled="true"
                                  v-model="amountCHF"
                                 ></v-text-field>
                             </v-col>
                             <v-col cols="4">
                                 <v-text-field
                                  :disabled="true"
                                  v-model="amountCHW"
                                  :label="$t('Debtors.amount_CHW')"
                                 >
                                 </v-text-field>
                             </v-col>
                             <v-col cols="4">
                                 <custom-currency 
                                  v-on:input="calculatePercentageAmount" 
                                  v-model="amount" 
                                  :show="amountStatus"  
                                 />
                             </v-col>
                        </v-row>
                        <custom-currency
                            v-if=" currency!='CHF/CHW' "
                            v-on:input="calculatePercentageAmount" 
                            v-model="amount" 
                            :show="amountStatus"
                        />
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.amount_field')"
                            v-show="amountStatus"
                           :disabled="true"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field
                            :label="$t('qrGenerator.payableBy.unstructuredMessage_field')"
                            v-model="unstructuredMessage"
                            :value="message => $event.target.value"
                            counter="70"
                            :rules="[rules.unstructuredMessage, rules.noInvalidCharacters]"
                        ></v-text-field>
                    </v-col>
                </v-row>
        </v-card-text>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { mask } from "vue-the-mask";
import {validateCharac} from "../../functions/invalidCharacters";

// import { VMoney } from "v-money";
import userMixin from '@/mixins/user.mixin';
import qrCurrencyVue from './qr.currency.vue';
export default {
    mixins: [userMixin],
    directives: {
        mask,
    },
    components: {
        'custom-currency': qrCurrencyVue
    },
    data() {
        return {
            tab: "tab-1",
            select_debitor: 0,
            show:false,
            debitorData: [],
            debtors: [],
            debitors: [],
            //percentageWir:0,
            wirValue:0,
            amountCHF:null,
            amountCHW:null,
            // amount: this.$store.state.QR.amount,
            rules: {
                debitorFullName: value => {
                    let currentName = this.debitor.name;
                    let currentLastName = this.debitor.last_name;
                    if(currentName && currentLastName){
                        return (this.debitor.name.length + this.debitor.last_name.length <= 70) 
                        || this.$t("qrGeneratorFree.rules.debitorFullName");
                    }else{
                        return true;
                    }
                },
                address: value => (value.length <= 60) || this.$t("qrGeneratorFree.rules.address"),
                zip: value => {
                    if(value){
                        if (typeof value !== "string")
                            return (
                            String(value).length <= 16 ||
                            this.$t("qrGeneratorFree.rules.zip")
                            );
                        return (
                            value.length <= 16 ||this.$t("qrGeneratorFree.rules.zip")
                        );
                    }else{
                        return true
                    }
                },
                nameMissingZip: value => {
                    if (this.debitor.name.length > 0) {
                        return !!this.debitor.address.zip || this.$t("qrGeneratorFree.rules.zipRequired");
                    } else {
                        return true;
                    }
                },
                city: value => (value.length <= 30) || this.$t("qrGeneratorFree.rules.city"),
                nameMissingCity: value => {
                    if (this.debitor.name.length > 0) {
                        return (
                        !!this.debitor.address.city ||
                        this.$t("qrGeneratorFree.rules.cityRequired")
                        );
                    } else {
                        return true;
                    }
                },
                debitorCountryRules: value => {
                    if (this.debitor.name.length > 0) {
                        return (
                        !!value || this.$t("qrGeneratorFree.rules.debitorCountryRules")
                        );
                    } else {
                        return true;
                    }  
                },
                amount: value => (value.length <= 12) || this.$t("qrGeneratorFree.rules.amount"),
                unstructuredMessage: value => {
                    value = value || "";
                    return value.length < 70 || this.$t("qrGeneratorFree.rules.unstructuredMessage");;
                },
                percentage: value =>  value >= 0 && value <= 100 || "The number must be greater than 0 and less than or equal to 100",
                noInvalidCharacters: value =>{
                    if(validateCharac(value)){
                        return this.$t("Globals.error_character");
                    }else{
                        return true;
                    }
                }
            }
        }
    },
    computed: {
        
        ...mapGetters({
            debitor: 'QR/debitor',
        }),

        percentageWir:{
            set(value){
                return this.$store.commit("QR/setPercentage", value ) ;
            },
            get(){
                return this.$store.state.QR.wirPercentage;
            }
        },

        /*showFieldsWir:{
           get(){
              return this.$store.state.QR.showFieldsWir;
           },
        },*/

        showFieldWirPercentage(){
          return  !this.amountStatus && this.userHasWir; //&& this.showFieldsWir
        },

        userHasWir(){
            return this.$store.state.User.wir_active;
        },

        currency: {
            get() {
                return this.$store.state.QR.currency;
            },
            set(currency) {
                this.$store.commit("QR/setCurrency", currency);
            }
        },

        amountStatus: {
            get() {
                return this.$store.state.QR.stateAmount;
            },
            set(state) {
                if(state){
                    this.currency = 'CHF';
                }else{
                    this.percentageWirChange(this.percentageWir);
                }
                this.$store.commit("QR/setStateAmount", state);
            }
        },

        wirActive(){
              return this.$store.state.User.wir_active;
        },

        showAmounts(){
            return this.currency == 'CHF/CHW' && this.userHasWir; //&& this.showFieldsWir;
        },
        

        getCurrencies(){

            let currencies = null;
            if(this.wirActive){

                currencies = [ 
                                { currency: 'CHF', code: 'CHF' },
                                { currency: 'CHW', code: 'CHW' },
                                { currency: 'CHF/CHW ', code: 'CHF/CHW' }
                             ]

            }else{
                currencies =  [ { currency: 'CHF', code: 'CHF' },
                                { currency: 'EUR', code: 'EUR' } ]
            }
            
            return currencies;

        },

        unstructuredMessage: {
            get() {
                return this.$store.state.AI.unstructuredMessage;
            },
            set(message) {
                this.$store.commit("AI/unstructuredMessage", {
                    unstructuredMessage: message
                });
            }
        },

        amount: {
            get() {    
                return this.$store.state.QR.amount
            },
            set(newValue) {
                this.$store.commit('QR/setAmount', newValue)
            }
        },

        streetRequired() {
            const isRequiered = this.isStreetRequiered;
            if(isRequiered){
                if(!this.debitor.address.street) {
                    return this.$t("rules.street_rule");
                }
                return true
            }
            return true;
        },

        nameRequired(){
            const nameRequired = this.isNameRequired;
            if(nameRequired){
                if(!this.debitor.name){
                    return this.$t("rules.required");
                }
                return true
            }
            return true;
        },

        cityRequired(){
            const cityRequired = this.isCityRequired;
            if(cityRequired){
                if(!this.debitor.address.city){
                    return  this.$t("rules.required");
                }
                return true
            }
            return true;
        },
        
        countryRequired(){
            const countryRequired = this.isCountryRequired;
            if(countryRequired){
                if(!this.debitor.address.country){
                    return  this.$t("rules.required");
                }
                return true
            }
            return true;
        },
        
        zipRequired(){
            const zipRequired = this.isZipRequired;
            if(zipRequired){
                if(!this.debitor.address.zip){
                    return  this.$t("rules.required");
                }
                return true
            }
            return true;
        },
        
        currencyRequired(){
            const currencyRequired = this.isCurrencyRequired;
            if(currencyRequired){
                if(!this.currency){
                    return  this.$t("rules.required");
                }
                return true
            }
            return true;
        }

    },
    methods: {
        changeGenButtonState() {
             //Depending on which tab is selected the invoce dates fields & the generate button are disabled
            if (this.tab === "tab-1") {
                this.invoiceDatesStatus = false;
                this.filePermission = true;
            } else {
                this.invoiceDatesStatus = true;
                if (this.pdfFile) {
                this.filePermission = true;
                } else {
                this.filePermission = false;
                }
            }
        },

        calculatePercentageAmount(){
       
            if(this.amount && this.percentageWir){

                //format percentage
                let percentageWir = String(this.percentageWir);

                percentageWir = percentageWir.indexOf('.') >= 0 ? percentageWir.substring(0,percentageWir.indexOf('.')+2): percentageWir+".0";
                
                percentageWir = Number(percentageWir);

                //calculate amount CHW
                let amount_CHW =  (( percentageWir / 100 ) * this.amount )
                let decimalCHW  =  Math.floor((amount_CHW - Math.floor(amount_CHW)) * 100);
                let centesimaCHW = decimalCHW % 10;
                
                let result = 0;
                
                //rounder the amountCHW
                if( centesimaCHW >= 1 && centesimaCHW < 5 ){

                    let decimals =  Math.floor(decimalCHW / 10) / 10 + 0.05;
                    result = Math.floor(amount_CHW) +  Math.floor(decimals * 100) / 100 ;
                    this.amountCHW = this.formatNumber(String(result));

                }else if (centesimaCHW > 5 && centesimaCHW <= 9  ){
                    result = Math.floor(amount_CHW) + ((decimalCHW - centesimaCHW)  + 10) / 100;
                    this.amountCHW = this.formatNumber(String(result));
                }else{
                 
                    result = (Math.floor(amount_CHW) +  Math.floor((amount_CHW - Math.floor(amount_CHW)) * 100) / 100)
                    this.amountCHW =  this.formatNumber(String(result));
                }

                this.amountCHF =  (this.amount - this.amountCHW).toFixed(2);
            
            }

        },

        formatNumber(number){
            let formatNumber = number.split(".");
            return  formatNumber.length > 1 ? `${formatNumber[0]}.${formatNumber[1].padEnd(2,0)}` : formatNumber[0]+".00" ;
        },

        percentageWirChange(newPercentage){
              if( newPercentage > 0 && newPercentage < 100 ){
                this.currency = "CHF/CHW";
               
                this.$emit('qrWithWir',false);  
                this.$emit('qrBothCurrency',true); 
                

            }else if(newPercentage == 100) {
                this.currency = "CHW";
                
                this.$emit('qrWithWir',true);
                this.$emit('qrBothCurrency',false);
            
            }else {
                 this.currency = "CHF";
                 
                 this.$emit('qrWithWir',false);
                 this.$emit('qrBothCurrency',false);
                 
            }

            this.$store.commit("QR/setPercentage",this.percentageWir);
        },


        formatWirPercentage(){
            if(this.percentageWir){

                let percentage = this.percentageWir.toString();
                let indexDot = percentage.indexOf(".");
                
          
                if(indexDot >= 0){
                    if(indexDot + 1 < this.percentageWir.length - 1){
                        //we remove the last digit after dot because only allow 1 digit after dot
                        percentage = percentage.slice(0, indexDot + 2 ); 
                        this.percentageWir = Number(percentage);

                    }
                }
                
            }
        },

        debitor_changed() {
            //loads into the debtor's fields the data corresponding to the selected debitor
            const debitorFiltered = this.debitorData.filter(
                element => this.select_debitor.ID == element.id
            );
            
            if (debitorFiltered.length > 0) {
                const debitor = debitorFiltered[0];
                this.debitor.name            = debitor.name;
                this.debitor.last_name       = debitor.last_name;
                this.debitor.address.street  = debitor.street;
                this.debitor.address.number  = debitor.number;
                this.debitor.address.zip     = debitor.zip;
                this.debitor.address.city    = debitor.city;
                this.debitor.address.country = debitor.country;
                this.$store.commit("QR/setCurrency", debitor.currency);
            }
        },

        debitorAddressType() {
            // if the user hasnt entered all these information will be considered as combined address
            let structured =
                this.debitor.address.steet != "" &&
                this.debitor.address.number != "" &&
                this.debitor.address.zip != "" &&
                this.debitor.address.city != "";
            if (structured) {
                this.debitor.addressType = "S";
                this.debitor.numberCounter = 16;
            } else {
                (this.debitor.addressType = "K"), (this.debitor.numberCounter = 70);
            }
        },        
    },
    props: {
        isStreetRequiered: {
            type: Boolean,
            default: false,
        },
        isNameRequired:{
            type: Boolean,
            default: false
        },
         isCityRequired:{
            type: Boolean,
            default: false
        },
           isZipRequired:{
            type: Boolean,
            default: false
        },
        isCountryRequired:{
            type: Boolean,
            default: false
        },
        isCurrencyRequired:{
            type: Boolean,
            default: false
        }

    },
    watch:{
        percentageWir:function (newPercentage,oldPercentage) {
            this.percentageWirChange(newPercentage);
            this.calculatePercentageAmount();
        },
    }
}
</script>

<style>

    .disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: white;
      border-color: #d1dbe5;   
    }

</style>
<template>
  <!-- counter-value toma el valor del texto con el formato dinero y le quita los espacios y el .00 para mostrar visualmente que solo 
  se escribio 12 digitos -->
  <v-text-field
        v-show="!show"
        :label="$t('qrGenerator.payableBy.amount_field')"
        v-model="displayValue"
        @blur="isInputActive = false" 
        @focus="isInputActive = true"
        :rules="[rules.amount]"
        counter="12"
        :counter-value="v => v.replace(/\s|0.00/g, '').length"
        maxLength="12"
    >
  </v-text-field> 
</template>

<script>

export default {
    data() {
        return {
            isInputActive: false, 
            rules: {
                amount: value => (this.formatNumber(value) <= 12) || this.$t("qrGeneratorFree.rules.amount"),
            }
        }
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    let validation = validationTwelve(this.value);
                    return validation.toString();
                } else {
                    let validation = validationTwelve(this.value);
                    // Regular expression that converts the entered number to a money format: XXX XXX XXX.XX
                    return validation.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ")
                    //return this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ")
                }
            },
            set: function(modifiedValue) {
                let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                if (isNaN(newValue)) {
                    newValue = 0
                }
            
            this.$emit('input', newValue)
            }
        }
    },
    methods:{
        formatNumber(value){
            let noSpace = value.replace(/ /g, "");   //delete white space
            //let final = noSpace.replace(/\.00/g, '');    //delete .00

            return noSpace.length;
        }
        
    },
    props: ['value', 'show']
}

function validationTwelve(value){
    // This is a regular expresion that check if the amount format is correct, the
    // format that do this regex is first 9 numbers (the first number is from 1 to 9
    // and the rest of numbers 0 to 9), then a dot and finally 2 numbers from 0 to 9

    // We add ? in the last number, so the user can write for example 5.4 and the
    // Code will add a 0 at the end automatically to evit to delete the numbers
    // The final result will be 5.4 --> 5.40
    let expRegMax = /[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][.][0-9]?[0-9]$/;

    // These regular expresions works when a user write the decimals without reach the
    // 9 digits integer digits and with the decimals reach more than 11 or 10 digits on 
    // total (Note: Only the user can write 2 decimals, no more)
    let expRegMax11 = /[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][.][0-9]?[0-9]$/;
    let expRegMax10 = /[1-9][0-9][0-9][0-9][0-9][0-9][0-9][.][0-9]?[0-9]$/;

    // If user amount is less than 9 or equal to one of the regular expresions, just return the same value
    if(expRegMax.test(value) == true || expRegMax11.test(value) == true || expRegMax10.test(value) == true || value.toString().length <=9){
        return value;
    }else{
        
        // If my amount lenght is 10 and dont have decimals, quit 1 number
        // value is a int and javascript dont have a method to get the lenght of a int
        // So we convert this int into a string to be able to use the lenght method
        if(value.toString().length == 10){
            //Create an auxiliar value to save a string version of the value type int
            let aux1 = value.toString();
            // Remove 1 number from value type string and save it on a new variable
            let aux2 = aux1.substring(0, aux1.length - 1);
            // Return the new value with numbers removed, before doing so we return it 
            // to its original value of number
            return parseFloat(aux2);
        }
        
        // If my amount lenght is 11 and dont have decimals, quit 2 number
        // value is a int and javascript dont have a method to get the lenght of a int
        // So we convert this int into a string to be able to use the lenght method
        if(value.toString().length == 11){
            //Create an auxiliar value to save a string version of the value type int
            let aux1 = value.toString();
            // Remove 2 numbers from value type string and save it on a new variable
            let aux2 = aux1.substring(0, aux1.length - 2);
            // Return the new value with numbers removed, before doing so we return it 
            // to its original value of number
            return parseFloat(aux2);
        }
        
        // If my amount lenght is 12 and dont have decimals, quit 3 number
        // value is a int and javascript dont have a method to get the lenght of a int
        // So we convert this int into a string to be able to use the lenght method
        if(value.toString().length == 12){
            //Create an auxiliar value to save a string version of the value type int
            let aux1 = value.toString();
            // Remove 3 numbers from value type string and save it on a new variable
            let aux2 = aux1.substring(0, aux1.length - 3);
            // Return the new value with numbers removed, before doing so we return it 
            // to its original value of number
            return parseFloat(aux2);
        }
    }
}
</script>

<style>

</style>
<template>
  <v-form ref="form">
  <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t("qrGeneratorFree.billingInformation.main_title") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- <v-row> -->
            <v-row v-if="$route.name == 'qr-table'">
              <v-col>
                <v-checkbox v-model="enableBilling"
                  :label="$t('qrGenerator.billingInformation.checkbox_title')"
                  @change="Bringfrommaster"
                  >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  :label="$t('qrGenerator.billingInformation.billNumber_field')"
                  v-model="$store.state.AI.structuredMessage.bill_number"
                  :rules="[rules.noInvalidCharacters]"
                ></v-text-field>
              </v-col>
              <v-col class="py-0" >
                  <v-dialog
                      ref="billDate"
                      v-model="$store.state.AI.datePickers.billDate.active"
                      :return-value.sync="$store.state.AI.structuredMessage.voucherDate"
                      persistent
                      width="290px"
                  >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="$store.state.AI.structuredMessage.voucherDate"
                          :label="$t('qrGeneratorFree.billingInformation.documentDate_field.field_text')"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                          clearable
                          :rules="[rules.noInvalidCharacters]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="$store.state.AI.structuredMessage.voucherDate"
                        scrollable
                        :locale="$store.state.User.language"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          :color="$vuetify.theme.themes.light.blue.primary"
                          @click="$store.state.AI.datePickers.billDate.active = false"
                          dark
                          >{{$t("qrGeneratorFree.billingInformation.documentDate_field.cancel_button")}}</v-btn
                        >
                        <v-btn
                          text
                          :color="$vuetify.theme.themes.light.blue.primary"
                          @click="$refs.billDate.save($store.state.AI.structuredMessage.voucherDate)"
                          dark
                          >{{$t("qrGeneratorFree.billingInformation.documentDate_field.ok_button")}}</v-btn
                        >
                      </v-date-picker>
                  </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" >
                  <v-text-field
                  :label="$t('qrGeneratorFree.billingInformation.customerReference_field')"
                  v-model="CustomerReference"
                  :rules="[rules.noInvalidCharacters]"
                  ></v-text-field>
              </v-col>
              <v-col class="py-0">
                  <v-text-field
                  :label="$t('qrGeneratorFree.billingInformation.UIDNumber_field')"
                  v-model="UIDNumber"
                  prefix="CHE-"
                  v-mask="'###.###.###'"
                  :rules="[rules.noInvalidCharacters]"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" >
                  <v-row>
                    <v-col cols="12" class="py-0">
                        <v-card-title class="pa-0">{{ $t("qrGeneratorFree.billingInformation.vatDate_title") }}</v-card-title>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                        <v-dialog
                          ref="mwstStartDate"
                          v-model="datePickers.VAT_Date.start_date"
                          :return-value.sync="$store.state.AI.structuredMessage.VAT_Date.start_date"
                          persistent
                          width="290px"
                          >
                          <template v-slot:activator="{ on }">
                              <v-text-field
                              v-model="$store.state.AI.structuredMessage.VAT_Date.start_date"
                              :label="$t('qrGeneratorFree.billingInformation.vatStartDate_field.field_text')"
                              prepend-icon="event"
                              readonly
                              clearable
                              v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="$store.state.AI.structuredMessage.VAT_Date.start_date"
                              :locale="$store.state.User.language"
                              scrollable
                          >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  :color="$vuetify.theme.themes.light.blue.primary"
                                  @click="datePickers.VAT_Date.start_date = false"
                              >{{ $t("qrGeneratorFree.billingInformation.vatStartDate_field.cancel_button") }}</v-btn>
                              <v-btn
                                  text
                                  :color="$vuetify.theme.themes.light.blue.primary"
                                  @click="$refs.mwstStartDate.save($store.state.AI.structuredMessage.VAT_Date.start_date)"
                              >{{ $t( "qrGeneratorFree.billingInformation.vatStartDate_field.ok_button" ) }}</v-btn>
                          </v-date-picker>
                        </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-dialog
                        ref="mwstEndDate"
                        v-model="datePickers.VAT_Date.end_date"
                        :return-value.sync="$store.state.AI.structuredMessage.VAT_Date.end_date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="$store.state.AI.structuredMessage.VAT_Date.end_date"
                            :label="$t( 'qrGeneratorFree.billingInformation.vatEndDate_field.field_text' )"
                            prepend-icon="event"
                            readonly
                            clearable
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="$store.state.AI.structuredMessage.VAT_Date.end_date"
                          scrollable
                          :locale="$store.state.User.language"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="datePickers.VAT_Date.end_date = false"
                          >{{ $t("qrGeneratorFree.billingInformation.vatEndDate_field.cancel_button") }}</v-btn>
                          <v-btn
                            text
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="$refs.mwstEndDate.save( $store.state.AI.structuredMessage.VAT_Date.end_date )"
                          >{{ $t("qrGeneratorFree.billingInformation.vatEndDate_field.ok_button") }}</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" >
                  <v-row>
                    <v-col cols="12" class="py-0" >
                      <v-card-title class="py-0">
                        {{ $t("qrGeneratorFree.billingInformation.vatDetails_title") }}
                      </v-card-title>
                    </v-col>
                  </v-row>
                  <v-data-table
                    hide-default-footer
                    :headers="headers.headers.VAT_Details"
                    :items="items.VAT_Details"
                    :no-data-text="$t('qrGeneratorFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Details"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              v-on="on"
                              dark
                              >{{ $t("qrGeneratorFree.billingInformation.vatDetails_table.add_button") }}</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-select
                                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                                    :items="['0%', '2.8%', '7.7%']"
                                    item-text="currency"
                                    item-value="code"
                                    :label="$t('qrGeneratorFree.billingInformation.vatDetails_table.rate_title')"
                                    v-model="editedItems.VAT_Details.rate"
                                  ></v-select>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Details.amount"
                                    type="number"
                                    :label="$t('qrGeneratorFree.billingInformation.vatDetails_table.totalAmount_title')"
                                    :rules="[rules.noInvalidCharacters]"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="closeVatDetailsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatDetails_table.cancel_button")}}</v-btn
                              >
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="saveVatDetailsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatDetails_table.save_button")}}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editVatDetailsDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon small @click="deleteVatDetailsItem(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" >
                <v-row>
                  <v-col cols="12" class="py-0" >
                    <v-card-title class="pa-0">{{ $t("qrGeneratorFree.billingInformation.vatImportTax_title") }}</v-card-title>
                  </v-col>
                </v-row>
                <v-data-table
                    hide-default-footer
                    :headers="headers.headers.VAT_Import"
                    :items="items.VAT_Import"
                    :no-data-text="$t('qrGeneratorFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Import"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              v-on="on"
                              dark
                              >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.add_button")}}</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-select
                                    :items="['0%', '2.8%', '7.7%']"
                                    :label="$t('qrGeneratorFree.billingInformation.vatImportTax_table.rate_title')"
                                    v-model="editedItems.VAT_Import.rate"
                                  ></v-select>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Import.discount"
                                    :label="$t('qrGeneratorFree.billingInformation.vatImportTax_table.discount_title')"
                                    type="number"
                                    :rules="[rules.noInvalidCharacters]"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="closeVatImportDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.cancel_button")}}</v-btn
                              >
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="saveVatImportDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.vatImportTax_table.save_button")}}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editVatImportDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        small
                        @click="deleteVatImportItem(item)"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </v-data-table>

              </v-col>
              <v-col cols="6" >
                <v-row>
                  <v-col cols="12" class="py-0" >
                    <v-card-title class="pa-0">{{$t("qrGeneratorFree.billingInformation.conditions_title")}}</v-card-title>
                  </v-col>
                </v-row>
                <v-data-table
                    hide-default-footer
                    :headers="headers.headers.VAT_Conditions"
                    :items="items.VAT_Conditions"
                    :no-data-text="$t('qrGeneratorFree.miscellaneous.table.notDataAvailable')"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogs.VAT_Conditions"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              v-on="on"
                              dark
                              >{{$t("qrGeneratorFree.billingInformation.conditions_table.add_button")}}</v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-text-field
                                    :label="$t('qrGeneratorFree.billingInformation.conditions_table.discount_title')"
                                    type="number"
                                    v-model="editedItems.VAT_Conditions.discount"
                                    suffix="%"
                                  ></v-text-field>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="editedItems.VAT_Conditions.days"
                                    :label="$t('qrGeneratorFree.billingInformation.conditions_table.days_title')"
                                    :rules="[rules.noInvalidCharacters]"
                                  ></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="closeVatConditionsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.conditions_table.cancel_button")}}</v-btn>
                              <v-btn
                                :color="$vuetify.theme.themes.light.blue.primary"
                                @click="saveVatConditionsDialog"
                                dark
                                >{{$t("qrGeneratorFree.billingInformation.conditions_table.save_button")}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editVatConditionsDialog(item)"
                        >mdi-pencil</v-icon>
                      <v-icon
                        small
                        @click="deleteVatConditionsItem(item)"
                        >mdi-delete</v-icon>
                    </template>
                </v-data-table>
              </v-col>
            </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
  </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import User from '@/store/modules/user'
import { mask } from "vue-the-mask";
import {validateCharac} from "../../functions/invalidCharacters";

export default {
    directives: {
        mask,
    },
    data() {
        return {
          rules: {
            noInvalidCharacters: value =>{
              if(validateCharac(value)){
                return this.$t("Globals.error_character");
              }else{
                return true;
              }
            }
          },
            datePickers: {
                billDate: {
                    active: false,
                    date  : null
                },
                billDate2: {
                    active: false,
                    date  : null
                },
                VAT_Date: {
                    start_date: false,
                    end_date  : false
                }
            },

            // these are used to show the correct modal/dialog of property in billing information
            dialogs: {
              VAT_Date      : false,
              VAT_Import    : false,
              VAT_Conditions: false,
              VAT_Details: false,
            },

            // these items will store all the data that the user fills in the billing information
            items: {
              VAT_Date      : [],
              VAT_Details   : [],
              VAT_Import    : [],
              VAT_Conditions: []
            },
            editedItems: {
              VAT_Details: {
                rate: null,
                amount: null
              },
              VAT_Import: {
                rate: null,
                discount: null
              },
              VAT_Conditions: {
                discount: null,
                days: null
              }
            },

            defaultItems: {
              VAT_Details: {
                rate: null,
                amount: null
              },
              VAT_Import: {
                rate: null,
                discount: null
              },
              VAT_Conditions: {
                discount: null,
                days: null
              }
            },

            // this property is used to store the correct index that is being edited in billing information
            editedItem: -1,
            // CustomerReference: '',
            // UIDNumber: '',
        };
    },
    computed:{
      ...mapGetters({
        user: 'User/user',
      }),
      UIDNumber: {
        get() {
          return this.$store.state.AI.structuredMessage.UID_Number
        },
        set(value) {
          this.$store.state.AI.structuredMessage.UID_Number = value;
        }
      },
      enableBilling:{
        get(){
          return this.$store.state.AI.enableBilling
        },
        set(value){
          return this.$store.state.AI.enableBilling = value;
        }
      },
      headers(){
        return {
           headers: {
            VAT_Date: [
              { text:  this.$t("qrGeneratorFree.billingInformation.start_date"), value: "start_date" },
              { text: this.$t("qrGeneratorFree.billingInformation.end_date"), value: "end_date" },
              { text:  this.$t("qrGeneratorFree.billingInformation.actions"), value: "actions", sortable: false }
            ],
            VAT_Details: [
              {
                text: this.$t("qrGeneratorFree.billingInformation.vatDetails_table.rate_title"),value: "rate"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.vatDetails_table.totalAmount_title"
                ),
                value: "amount"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.vatDetails_table.actions_title"
                ),
                value: "actions",
                sortable: false
              }
            ],
            VAT_Import: [
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.vatImportTax_table.rate_title"
                ),
                value: "rate"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.vatImportTax_table.discount_title"
                ),
                value: "discount"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.vatImportTax_table.actions_title"
                ),
                value: "actions",
                sortable: false
              }
            ],
            VAT_Conditions: [
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.conditions_table.discount_title"
                ),
                value: "discount"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.conditions_table.days_title"
                ),
                value: "days"
              },
              {
                text: this.$t(
                  "qrGeneratorFree.billingInformation.conditions_table.actions_title"
                ),
                value: "actions",
                sortable: false
              }
            ]
          },
        }
      },
      
      CustomerReference: {
        get() {
          return this.$store.state.AI.structuredMessage.customerReference;
        },
        set(value) {
          this.$store.state.AI.structuredMessage.customerReference = value
        }
      }
    },
    mounted(){
      setTimeout(() => {
        // TODO: improve later, i couldn't make this works correctly because im not being able to get access to the store
        this.Bringfrommaster();
      }, 1000);
    },
    methods: {
      /**
       * these repetitive methods correspond to the section
       * "billing information" on the QR Form.
       */
      closeVatDetailsDialog() {
        this.dialogs.VAT_Details = false;
        this.editedItems.VAT_Details = Object.assign(
          {},
          this.defaultItems.VAT_Details
        );
        this.editedItem = -1;
      },
      saveVatDetailsDialog() {
        let rate = this.editedItems.VAT_Details.rate;
        let amount = this.editedItems.VAT_Details.amount;
        // if its a new value
        if (this.editedItem == -1) {
          this.items.VAT_Details.push({
            rate,
            amount
          });
          // otherwise if it already exists, just will replace the data
        } else {
          Object.assign(this.items.VAT_Details[this.editedItem], {
            rate,
            amount
          });
        }
        (this.editedItems.VAT_Details.amount = null),
          (this.editedItems.VAT_Details.rate = null),
          (this.editedItem = -1);
        // the informaton its saved in the store
        this.$store.commit("AI/setVAT_Details", {
          VAT_Details: this.items.VAT_Details
        });
        // the form/dialog closes
        this.closeVatDetailsDialog();
      },
      /**
       * i really dont know how this method gets the item information
       * i suppouse that it can be obtained from the v-slot property in the template
       */
      editVatDetailsDialog(item) {
        let { rate } = item;
        let { amount } = item;
        // Set the index of the element that its being edited
        this.editedItem = this.items.VAT_Details.indexOf(item);
        // Set the data to edit
        this.editedItems.VAT_Details.rate = rate;
        this.editedItems.VAT_Details.amount = amount;
        // finally opens the dialog to let the user modify the data
        this.dialogs.VAT_Details = true;
      },

      deleteVatDetailsItem(item) {
        // Get the index of the item
        const index = this.items.VAT_Details.indexOf(item);
        // if the user agrees will be deleted
        // TODO: Phase 2, this confirm would be cool if we use v-dialog to replace the native confirm
        this.$root
          .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
            color: "red"
          })
          .then(confirm => {
            if (confirm) {
              this.items.VAT_Details.splice(index, 1);
            }
          });
      },

      // VAT Import CRUD:
      closeVatImportDialog() {
        this.dialogs.VAT_Import = false;
        this.editedItems.VAT_Import = Object.assign(
          {},
          this.defaultItems.VAT_Import
        );
        this.editedItem = -1;
      },

      saveVatImportDialog() {
        let rate = this.editedItems.VAT_Import.rate;
        let discount = this.editedItems.VAT_Import.discount;
        // if its a new value
        if (this.editedItem == -1) {
          this.items.VAT_Import.push({
            rate,
            discount
          });
          // otherwise if it already exists, just will replace the data
        } else {
          Object.assign(this.items.VAT_Import[this.editedItem], {
            rate,
            discount
          });
        }
        (this.editedItems.VAT_Import.discount = null),
          (this.editedItems.VAT_Import.rate = null),
          (this.editedItem = -1);
        // the informaton its saved in the store
        this.$store.commit("AI/setVAT_Import", {
          VAT_Import: this.items.VAT_Import
        });
        // the form/dialog closes
        this.closeVatImportDialog();
      },

      editVatImportDialog(item) {
        let { rate } = item;
        let { discount } = item;
        // Set the index of the element that its being edited
        this.editedItem = this.items.VAT_Import.indexOf(item);
        // Set the data to edit
        this.editedItems.VAT_Import.rate = rate;
        this.editedItems.VAT_Import.discount = discount;
        // finally opens the dialog to let the user modify the data
        this.dialogs.VAT_Import = true;
      },

      deleteVatImportItem(item) {
        // Get the index of the item
        const index = this.items.VAT_Import.indexOf(item);
        // if the user agrees will be deleted
        this.$root
          .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
            color: "red"
          })
          .then(confirm => {
            if (confirm) {
              this.items.VAT_Import.splice(index, 1);
            }
          });
      },

      // VAT Conditions CRUD:
      closeVatConditionsDialog() {
        this.dialogs.VAT_Conditions = false;
        this.editedItems.VAT_Conditions = Object.assign(
          {},
          this.defaultItems.VAT_Conditions
        );
        this.editedItem = -1;
      },

      saveVatConditionsDialog() {
        let discount = "";
        if (
          this.editedItems.VAT_Conditions.discount !== undefined &&
          this.editedItems.VAT_Conditions.discount !== "" &&
          this.editedItems.VAT_Conditions.discount !== null
        ) {
          if (this.editedItems.VAT_Conditions.discount.match(/\%/g) !== null) {
            this.editedItems.VAT_Conditions.discount = this.editedItems.VAT_Conditions.discount.replace(
              /\%/g,
              ""
            );
          }
          discount = `${this.editedItems.VAT_Conditions.discount}%`;
        }
        let days = this.editedItems.VAT_Conditions.days;
        // if its a new value
        if (this.editedItem == -1) {
          this.items.VAT_Conditions.push({
            days,
            discount
          });
          // otherwise if it already exists, just will replace the data
        } else {
          Object.assign(this.items.VAT_Conditions[this.editedItem], {
            days,
            discount
          });
        }
        (this.editedItems.VAT_Conditions.discount = null),
          (this.editedItems.VAT_Conditions.days = null),
          (this.editedItem = -1);
        // the informaton its saved in the store
        this.$store.commit("AI/setVAT_Conditions", {
          VAT_Conditions: this.items.VAT_Conditions
        });
        // the form/dialog closes
        this.closeVatConditionsDialog();
      },

      editVatConditionsDialog(item) {
        let { days } = item;
        let { discount } = item;
        // Set the index of the element that its being edited
        this.editedItem = this.items.VAT_Conditions.indexOf(item);
        // Set the data to edit
        this.editedItems.VAT_Conditions.days = days;
        if (discount !== undefined) {
          discount = discount.replace(/\%/g, "");
        }
        this.editedItems.VAT_Conditions.discount = discount;
        // finally opens the dialog to let the user modify the data
        this.dialogs.VAT_Conditions = true;
      },

      deleteVatConditionsItem(item) {
        // Get the index of the item
        const index = this.items.VAT_Conditions.indexOf(item);
        // if the user agrees will be deleted
        this.$root
          .$confirm(this.$t("Globals.delete"), this.$t("Globals.ask_delete"), {
            color: "red"
          })
          .then(confirm => {
            if (confirm) {
              this.items.VAT_Conditions.splice(index, 1);
            }
          });
      },

      Bringfrommaster() {
        this.$nextTick(() => {
          if(this.enableBilling) {
            this.UIDNumber = this.user.numberuid;
            this.CustomerReference = this.user.referen || this.user.reference;
          } else {
              this.UIDNumber = '';
              // TODO: improve this later
              setTimeout(() => {
                this.UIDNumber = '';
              }, 10);
              this.CustomerReference = '';
          }
        })
      },
    }
};
</script>

<style>

</style>

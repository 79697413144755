<template>
  <div class="container-debtors">
    <v-row>
      <v-col cols="3" class="pt-0 mt-0 pl-5 searcontent">
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('Debtors.search')" class="mt-0" single-line
          hide-details dense></v-text-field>
      </v-col>
      <v-col cols="5" class="pt-0 pb-0">
        <v-container class="backColor containerBtnBexio">
          <v-btn v-if="connectBexio" class="mb-2 btnBexio" @click="openLoginBexio()" :loading="loadingBtn">{{
            $t("Debtors.Connectbexio") }}
          </v-btn>
          <v-btn v-if="actionsBexio" class="btnBexio heihtBtn mb-2" @click="OpenBexioDialog()">{{
            $t("Debtors.importBexio") }} <br />
            {{ $t("Debtors.seconImportBexio") }}
          </v-btn>
        </v-container>
      </v-col>
      <v-col cols="4" class="pt-0 pb-0 searcontent">
        <slot name="actionSlot"></slot>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- we use this wrapper to show the second scroll above of table -->
        <div id="wrapper2">
          <div id="div2" :class="userhasWir ? 'width-scroll-WIR' : 'width-scroll'"></div>
        </div>
      </v-col>
      <v-col>
        <v-data-table v-model="selected" height="65vh" show-select :headers="headers" :items="debtors" :search="search"
          class="elevation-1 table-debtors" :loading="loadingState" :loading-text="$t('CreditorFiles.loading')" dense
          calculate-widths :custom-sort="customSortDebtors" multi-sort :footer-props="{
            itemsPerPageOptions: [15, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }" :no-data-text="$t('qrGenerator.productsTable.data_available')">
          <template v-slot:item.error="{ item }">
            <v-icon color="red" class="ms-0" v-if="item.error">mdi-alert-outline</v-icon>
          </template> 
          <template v-slot:item.actions="{ item }">
            <v-icon class="mb-2" small @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon class="mb-2" small @click="deleteItem(item)">mdi-trash-can</v-icon>
          </template>
          <template v-slot:item.qr_status="{ item }">
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense :items="$t('Debtors.Status')"
              item-text="text" item-value="value" clearable @change="updateStatusDebtor(item)" v-model="item.qr_status"
              :loading="setLoadingDisabledStateStatus" :disabled="setLoadingDisabledStateStatus">
              <template v-slot:selection="{ item }">
                {{ calculateItemText(item) }}
              </template>
            </v-select>
          </template>
          <template v-slot:item.first_warning="{ item }">
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense :items="$t('Debtors.Status')"
              item-text="text" item-value="value" @change="updateStatusDebtor(item)" clearable
              v-model="item.first_warning" :loading="setLoadingDisabledStateStatus"
              :disabled="setLoadingDisabledStateStatus">
              <template v-slot:selection="{ item }">
                {{ calculateItemText(item) }}
              </template>
            </v-select>
          </template>
          <template v-slot:item.second_warning="{ item }">
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense :items="$t('Debtors.Status')"
              item-text="text" item-value="value" @change="updateStatusDebtor(item)" clearable
              v-model="item.second_warning" :loading="setLoadingDisabledStateStatus"
              :disabled="setLoadingDisabledStateStatus">
              <template v-slot:selection="{ item }">
                {{ calculateItemText(item) }}
              </template>
            </v-select>
          </template>
          <template v-slot:header.qr_status>
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" height="35px" dense clearable
              v-model="statusSelections.status.selected" @change="generalStatusFilter()"
              :items="$t('Debtors.StatusHeader')">
              <template v-slot:label>
                <span style="font-size: 13px">{{
                  $t("Debtors.bill_status")
                  }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span style="font-size: 13px">{{
                  calculateItemText(item)
                  }}</span>
              </template>
            </v-select>
          </template>
          <template v-slot:header.first_warning>
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" height="35px" dense clearable
              v-model="statusSelections.first.selected" @change="generalStatusFilter()"
              :items="$t('Debtors.StatusHeader')">
              <template v-slot:label>
                <span style="font-size: 13px">{{
                  $t("Debtors.first_warning")
                  }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span style="font-size: 13px">{{
                  calculateItemText(item)
                  }}</span>
              </template>
            </v-select>
          </template>
          <template v-slot:header.second_warning>
            <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" height="35px" dense clearable
              v-model="statusSelections.second.selected" @change="generalStatusFilter()"
              :items="$t('Debtors.StatusHeader')">
              <template v-slot:label>
                <span style="font-size: 13px">{{
                  $t("Debtors.second_warning")
                  }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span style="font-size: 13px">{{
                  calculateItemText(item)
                  }}</span>
              </template>
            </v-select>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showDialogFlag">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <v-form ref="debtorForm" v-model="debtorFormValid">
            <v-row>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" v-model="item.salutation"
                  :label="$t('StepByStep.UserInformation.salutation_title')" :items="this.$t('Debtors.Salutations')"
                  item-text="text" item-value="value" dense></v-select>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.firstname')" dense v-model="item.name"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.lastname')" dense v-model="item.last_name"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :label="$t('Debtors.address_suffix')" dense v-model="item.address_suffix"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.street')" dense v-model="item.street"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.number')" dense v-model="item.number"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :label="$t('Debtors.zip')" dense v-model="item.zip"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.city')" dense v-model="item.city"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" :items="$t('countryList')"
                  item-text="name" item-value="code" :label="$t('Debtors.country')" dense v-model="item.country"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select v-model="item.currency" :items="itemsCurrency" :label="$t('Debtors.currency')"
                  :rules="[rules.isEmpty, rules.noInvalidCharacters]" dense></v-select>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.clientno')" dense v-model="item.client_number"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.member_category')" dense v-model="item.member_category"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>

            </v-row>
            <v-row> <v-col>
                <v-text-field :label="$t('Debtors.email')" dense v-model="item.email"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" v-model="item.language"
                  :items="$t('Debtors.Language')" item-text="text" item-value="value" :label="$t('Debtors.language')"
                  dense :rules="[rules.isEmpty, rules.noInvalidCharacters]"></v-select>
              </v-col>
              <v-col>
                <v-text-field :label="$t('Debtors.reference')" dense :rules="[
                  rules.validateReference,
                  rules.referenceLength,
                  rules.reference,
                  rules.noInvalidCharacters,
                ]" v-model="item.reference"></v-text-field>
              </v-col>

            </v-row>
            <v-row><v-col>
                <v-text-field :label="$t('Debtors.message')" dense v-model="item.message"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field dense :label="$t('Debtors.client_reference')" v-model="item.client_reference"
                  :rules="[rules.noInvalidCharacters]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <debtor-product-table ref="debtorProductTable" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{ $t("Debtors.status") }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense
                  :items="$t('Debtors.Status')" item-text="text" item-value="value" :label="$t('Debtors.bill_status')"
                  clearable v-model="item.qr_status"></v-select>
              </v-col>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense
                  :items="$t('Debtors.Status')" item-text="text" item-value="value" :label="$t('Debtors.first_warning')"
                  clearable v-model="item.first_warning"></v-select>
              </v-col>
              <v-col>
                <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')" dense
                  :items="$t('Debtors.Status')" item-text="text" item-value="value"
                  :label="$t('Debtors.second_warning')" clearable v-model="item.second_warning"></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="pt-5">
              <h3>{{ $t("Debtors.billing_subtitle") }}</h3>
              <v-row>
                <v-col>
                  <v-text-field :label="$t('Debtors.invoiceno')" dense v-model="item.billing_information.invoice_no"
                    :rules="[rules.noInvalidCharacters]"></v-text-field>
                </v-col>
                <v-col>
                  <v-menu ref="menu_invoice_date" v-model="menu_invoice_date" :close-on-content-click="false"
                    :return-value.sync="item.billing_information.invoice_date" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="item.billing_information.invoice_date"
                        :label="$t('QrSeries.step_three.invoice_date')" :rules="[rules.isEmpty]"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="item.billing_information.invoice_date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text :color="$vuetify.theme.themes.light.blue.primary" @click="menu_invoice_date = false">
                        {{ $t("Globals.cancel") }}
                      </v-btn>
                      <v-btn text :color="$vuetify.theme.themes.light.blue.primary" @click="
                        $refs.menu_invoice_date.save(
                          item.billing_information.invoice_date
                        )
                        ">
                        {{ $t("Globals.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu ref="menu_vat_date" v-model="menu_vat_date" :close-on-content-click="false"
                    :return-value.sync="item.billing_information.vat_date" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="item.billing_information.vat_date" :label="$t('Debtors.vat_date')"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="item.billing_information.vat_date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text :color="$vuetify.theme.themes.light.blue.primary" @click="menu_vat_date = false">
                        {{ $t("Globals.cancel") }}
                      </v-btn>
                      <v-btn text :color="$vuetify.theme.themes.light.blue.primary" @click="
                        $refs.menu_vat_date.save(
                          item.billing_information.vat_date
                        )
                        ">
                        {{ $t("Globals.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field dense :label="$t('Debtors.client_reference')" disabled
                    v-model="item.billing_information.client_reference"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field dense :prefix="$t('Debtors.prefix')" :label="$t('Debtors.UID')" disabled
                    v-model="item.billing_information.UID" :rules="[rules.noInvalidCharacters]"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field dense :label="$t('Debtors.vat_date_percentage')" type="number"
                    v-model="item.billing_information.VAT"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field dense :label="$t('Debtors.cash_discount')" type="number"
                    v-model="item.billing_information.discount"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field dense :label="$t('Debtors.payment_deadline')" type="number"
                    v-model="item.billing_information.payment_deadline"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark @click="hideDialog">{{ $t("Debtors.btn_cancel")
            }}</v-btn>
          <v-btn :loading="showDialogSaveLoader" @click="saveDialogChanges"
            :color="$vuetify.theme.themes.light.blue.primary" dark>{{ $t("Debtors.btn_save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------- Dialog Bexio ----------------------------------------- -->
    <v-dialog v-model="dialogbexio" hide-overlay transition="dialog-bottom-transition" max-width="600" max-height="400">
      <v-card>
        <v-card-title>
          {{ $t("Debtors.CriteriaTitle") }}
        </v-card-title>
        <v-card-text class="containerAll">
          <v-row>
            <v-col>
              <h3>{{ $t("Debtors.CriteriaIntertitle1") }}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFrom" :label="$t('Debtors.CriteriaFrom')" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dateFrom" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStart = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuFrom.save(dateFrom)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu ref="menuUntil" v-model="menuUntil" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateUntil" :label="$t('Debtors.CriteriaUntil')" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="dateUntil" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuUntil = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuUntil.save(dateUntil)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>{{ $t("Debtors.CriteriaIntertitle2") }}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="statusInvoices" :label="$t('Debtors.CriteriaStatus')"
                :items="$t('Debtors.bexioStatus')" multiple></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark @click="hideImportDialog">{{
            $t("Debtors.btn_cancel") }}</v-btn>
          <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark :loading="showImportDialogSaveLoader"
            @click="saveImportDialogChanges">{{ $t("Debtors.btn_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---->
    <v-dialog v-model="dialogResponseImport.show" hide-overlay transition="dialog-bottom-transition" max-width="600"
      max-height="400">
      <v-card>
        <v-card-title>
          {{ $t("Debtors.Alert.title") }}
        </v-card-title>
        <v-card-text class="containerAll">
          <v-row>
            <v-col>
              <h3 v-if="dialogResponseImport.status === 200">
                {{ $t("Debtors.Alert.msgSucess") }}
              </h3>
              <h3 v-else-if="dialogResponseImport.status === 204">
                {{ $t("Debtors.Alert.msgDataNotFound") }}
              </h3>
              <h3 v-else>{{ $t("Debtors.Alert.msgFail") }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark :loading="showImportDialogSaveLoader"
            @click="hiddeDialogResponse()">{{ $t("Debtors.Alert.btn_ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DebtorProductsTable from "./debtors.table.productsTable.vue";
import { mapGetters } from "vuex";
import { QRR } from "@/functions/reference";
import ISO11649 from "iso-11649";
import bexioConnect from "../../My Profile/BexioConnect/BexioView.vue";
import { validateCharac } from "../../../../functions/invalidCharacters";

const detector = require("../../../../detectTypeReference.js");
export default {
  components: {
    bexioConnect,
    "debtor-product-table": DebtorProductsTable,
  },
  data() {
    return {
      search: "",
      loadingBtn: false,
      connectBexio: false,
      actionsBexio: false,
      dialogResponseImport: { status: null, show: false },
      dialogbexio: false,
      statusSelections: {
        status: {
          selected: null,
        },
        first: {
          selected: null,
        },
        second: {
          selected: null,
        },
        filtersOrder: [],
      },
      statusInvoices: [],
      filters: {
        status: "",
        first: "",
        second: "",
      },
      showDialogFlag: false,
      loadingState: false,
      currentItemIndex: null,
      menu_vat_date: false,
      menu_invoice_date: false,
      showDialogSaveLoader: false,
      setLoadingDisabledStateStatus: false,
      debtorsAux: [],
      item: {
        id: null,
        last_modified_date: null,
        salutation: "",
        salutationSearch: "",
        name: "",
        last_name: "",
        address_suffix: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        currency: "",
        products_json: "",
        language: "",
        languageSearch: "",
        reference: "",
        message: "",
        client_number: "",
        member_category: "",
        email: "",
        qr_status: "",
        first_warning: "",
        second_warning: "",
        client_reference: "",
        billing_information: {
          invoice_no: "",
          invoice_date: "",
          UID: "",
          client_reference: "",
          vat_date: "",
          VAT: 0,
          discount: 0,
          payment_deadline: 0,
        },
      },
      default_item: {
        id: null,
        last_modified_date: null,
        salutation: "",
        salutationSearch: "",
        name: "",
        last_name: "",
        address_suffix: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        currency: "",
        products_json: "",
        language: "",
        languageSearch: "",
        reference: "",
        message: "",
        client_number: "",
        member_category: "",
        email: "",
        qr_status: "",
        first_warning: "",
        second_warning: "",
        client_reference: "",
        billing_information: {
          invoice_no: "",
          invoice_date: "",
          client_reference: "",
          UID: "",
          vat_date: "",
          VAT: 0,
          discount: 0,
          payment_deadline: 0,
        },
      },
      rules: {
        isEmpty: (v) => {
          return !!v || `Field required`;
        },
        isType: (v) => {
          if (v) {
            let MIMETypesArrays = [
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "text/csv",
            ];
            let matchedMIME = MIMETypesArrays.find(
              (element) => element === v.type
            );
            if (matchedMIME) return true;
            return this.$t("Debtors.file_type");
          } else {
            return true;
          }
        },
        validateReference: (reference) => {
          if (reference && reference.length > 0) {
            const typeReference = detector.detectTypeReference(
              reference.trim()
            );
            return typeReference != "QRR" && typeReference != "SCOR"
              ? "The reference is invalid"
              : true;
          }
          return true;
        },
        referenceLength: (value) => {

          if (!value) {
            return true
          }

          const typeReference = detector.detectTypeReference(value ? value.trim() : '');
          let reference = value.replace(/ /g, "");
          if (typeReference === "QRR") {
            if (reference.length !== 27) {
              return this.$t("qrGeneratorFree.rules.referenceLength.QRR");
            } else {
              return true;
            }
          } else if (typeReference === "SCOR") {
            if (!(reference.length >= 5 && reference.length <= 25)) {
              return this.$t("qrGeneratorFree.rules.referenceLength.SCOR");
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
        reference: (value) => {
          if (!value) {
            return true
          }

          const typeReference = detector.detectTypeReference(value.trim());
          let reference = value.replace(/ /g, "");
          if (typeReference === "QRR") {
            // Get the digit that should be the last digit in the reference
            let lastDigit = QRR(reference);
            if (reference.charAt(27) != lastDigit) {
              return this.$t("qrGeneratorFree.rules.reference.QRR");
            } else {
              return true;
            }
          } else if (typeReference === "SCOR") {
            if (reference.length >= 5 && reference.length <= 25) {
              try {
                let validation = ISO11649.validate(reference);
                return (
                  validation ||
                  this.$t("qrGeneratorFree.rules.invalid_reference")
                );
              } catch (e) {
                return this.$t("qrGeneratorFree.rules.reference.SCOR.invalid");
              }
            } else {
              return this.$t(
                "qrGeneratorFree.rules.reference.SCOR.invalidLength"
              );
            }
          }
          return true;
        },
        noInvalidCharacters: (value) => {
          if (validateCharac(value)) {
            return this.$t("Globals.error_character");
          } else {
            return true;
          }
        },
      },
      menuFrom: false,
      dateFrom: this.getDefaultDateFrom(),
      menuUntil: false,
      dateUntil: this.getDefaultDateUntil(),
      showImportDialogSaveLoader: false,
      bexioStatus: [
        {
          text: "Draft",
          value: 7,
        },
        {
          text: "Open",
          value: 8,
        },
        {
          text: "Paid",
          value: 9,
        },
        {
          text: "Partial",
          value: 16,
        },
        {
          text: "Overdue",
          value: 31,
        },
        {
          text: "All",
          value: 0,
        },
      ],
      allSelected: false,
      debtorFormValid: true
    };
  },
  watch: {
    showDialogFlag(visible) {
      if (visible) {
        this.$nextTick(() => {
          if (
            this.currentItemIndex !== null &&
            this.currentItemIndex !== undefined
          ) {
            this.$refs.debtorForm.validate();
          } else {
            this.$refs.debtorForm.resetValidation();
          }
        });
      }
    },
    locale(newLocale, oldLocale) {
      let debtorsCopy = this.debtors;
      debtorsCopy.map((element) => this.setItemsByLanguage(element));
      this.debtors = debtorsCopy;
      if (this.debtorsAux.length > 0) {
        this.debtorsAux.map((element) => this.setItemsByLanguage(element));
      }
    },
    statusInvoices(newVal, oldValue) {
      if (
        (newVal.includes(0) ||
          newVal.filter((status) => [7, 8, 9, 16, 31].includes(status))
            .length == 5) &&
        !this.allSelected
      ) {
        this.statusInvoices = [7, 8, 9, 16, 31, 0];
        this.allSelected = true;
      } else if (!newVal.includes(0) && this.allSelected) {
        this.statusInvoices = [];
        this.allSelected = false;
      } else if (
        newVal.filter((status) => [7, 8, 9, 16, 31].includes(status)).length <
        5 &&
        this.allSelected
      ) {
        this.statusInvoices = this.statusInvoices.filter((s) => s != 0);
        this.allSelected = false;
      }
    },
  },
  computed: {
    itemsCurrency() {
      if (this.userhasWir) {
        return ["CHF"];
      } else {
        return ["CHF", "EUR"];
      }
    },

    columns() {
      return [
        { text: "Id", align: "start", value: "id", width: 70 },
        { text: this.$t("Debtors.firstname"), value: "name", width: 140 },
        { text: this.$t("Debtors.lastname"), value: "last_name", width: 140 },
        {
          text: this.$t("Debtors.clientno"),
          value: "client_number",
          width: 30,
        },
        {
          text: this.$t('Debtors.member_category'),
          value: "member_category",
          width: 30,
        },
        { text: "", value: "error", width: 60 },
        {
          text: this.$t("Debtors.actions"),
          value: "actions",
          sortable: false,
          width: 100,
        },
        {
          text: this.$t("Debtors.last_modified"),
          value: "last_modified_date",
          width: 160,
        },
        {
          text: this.$t("Debtors.bill_status"),
          value: "qr_status",
          sortable: false,
          width: 205,
        },
        {
          text: this.$t("Debtors.first_warning"),
          value: "first_warning",
          sortable: false,
          width: 205,
        },
        {
          text: this.$t("Debtors.second_warning"),
          value: "second_warning",
          sortable: false,
          width: 205,
        },
        { text: this.$t("Debtors.reference"), value: "reference", width: 250 },
        {
          text: this.$t("Debtors.salutation"),
          value: "salutationSearch",
          width: 180,
        },
        {
          text: this.$t("Debtors.language"),
          value: "languageSearch",
          width: 140,
        },
        {
          text: this.$t("Debtors.invoice_amount"),
          value: "invoice_amount",
          width: 170,
        },
        {
          text: this.$t("Debtors.amount_CHF"),
          value: "amount_CHF",
          width: 140,
        },
        {
          text: this.$t("Debtors.amount_CHW"),
          value: "amount_CHW",
          width: 140,
        },
        { text: this.$t("Debtors.street"), value: "street", width: 140 },
        { text: this.$t("Debtors.number"), value: "number", width: 170 },
        { text: this.$t("Debtors.zip"), value: "zip", width: 140 },
        { text: this.$t("Debtors.city"), value: "city", width: 100 },
        { text: this.$t("Debtors.country"), value: "country", width: 100 },
        { text: this.$t("Debtors.email"), value: "email", width: 100 },
      ];
    },

    headers() {
      if (this.hasWir) {
        return this.columns;
      } else {
        let ignoreColumns = ["amount_CHF", "amount_CHW"];
        return this.columns.filter(
          (element) => !ignoreColumns.includes(element.value)
        );
      }
    },

    userhasWir() {
      return this.$store.state.User.wir_active;
    },

    dialogTitle() {
      if (
        this.currentItemIndex !== undefined &&
        this.currentItemIndex !== null
      ) {
        return this.$t("Debtors.OverFlowButtons.updatedebtor");
      } else {
        return this.$t("Debtors.OverFlowButtons.adddebtor");
      }
    },

    hasWir() {
      return this.$store.state.User.wir_active;
    },
    ...mapGetters({
      locale: "User/getLanguage",
    }),
    debtors: {
      get() {
        return [...this.$store.state.Debtor.debtors];
      },
      set(value) {
        return (this.$store.state.Debtor.debtors = [...value]);
      },
    },
    selected: {
      get() {
        return [...this.$store.state.Debtor.debtors_selected];
      },
      set(value) {
        return (this.$store.state.Debtor.debtors_selected = [...value]);
      },
    },
  },
  async mounted() {
    window.addEventListener("message", this.handleMessage, false);
    this.loadDebtorsList();
    this.default_item.billing_information.UID =
      this.$store.state.User.username.UID;
    this.default_item.billing_information.client_reference =
      this.$store.state.User.username.reference;

    //this lines of code  is to bring the two scrolls horizontal in table
    let wrapper_1 = document.querySelector(
      ".table-debtors .v-data-table__wrapper"
    );
    let wrapper_2 = document.querySelector("#wrapper2");
    wrapper_1.onscroll = function () {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
    wrapper_2.onscroll = function () {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };

    await this.connection();
  },
  methods: {
    generalStatusFilter() {
      if (this.debtorsAux.length <= 0) this.debtorsAux = [...this.debtors];
      this.debtors = this.debtorsAux;
      this.debtors = this.debtors.filter((element) =>
        this.filtersNewValidation(element)
      );
    },
    showDialog() {
      Object.assign(this.item, this.default_item);
      this.currentItemIndex = null;
      this.showDialogFlag = true;
      return this.$nextTick(() => {
        this.$refs.debtorProductTable.productItems = new Array();
      });
    },
    calculateInvoiceAmount(productItems, billing_round_difference) {
      let totalAmount = 0; 
      if (productItems) {
        productItems.forEach(item => {
          if (item.vatMethod === 'add') {
            totalAmount += Number(item.grossPriceProduct)
          } else {
            totalAmount += Number(item.quantity) * Number(item.unitPrice);
          }
        });

        totalAmount += Number(billing_round_difference)

        let total = Math.round(totalAmount * Math.pow(10, 2)) / Math.pow(10, 2)
        total = total.toString().split(".")
        return total.length > 1
          ? `${total[0]}.${total[1].padEnd(2, 0)}`
          : total[0] + ".00";
      }
      return '0.00'

    },
    formatNumber(number) {
      let total = Math.round(number * Math.pow(10, 2)) / Math.pow(10, 2)
      let formatNumber = total.toString().split(".");
      return formatNumber.length > 1
        ? `${formatNumber[0]}.${formatNumber[1].padEnd(2, 0)}`
        : formatNumber[0] + ".00";
    },
    setdebtorsToZero() {
      //This function only serves to cancel step 2 from
      //the button and deselects the checkboxes.
      this.selected = [];
    },
    async loadDebtorsList() {
      this.loadingState = true;
      this.selected = new Array();
      this.statusSelections.status.selected = null;
      this.statusSelections.first.selected = null;
      this.statusSelections.second.selected = null;
      //We use the debtors array, which is cleaned by matching
      this.debtors = [];
      //We send the following fetch to get all the debitors of the account
      // with the session active
      this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/debtors`, {
        credentials: "include",
      })
        .then((response) => {
          console.log('response', response)
          //The cycle of which the response variable returns is traversed, which contains the
          // debitors returned by the server, it is getting line by line in the array
          // to show it in the table
          let auxArray = [];
          for (let i = 0; i <= response.data.length - 1; i++) {
            let currentRecord = response.data[i];

            //calculate the amount of CHF/CHW
            let infoProducts = JSON.parse(currentRecord.products_JSON);

            let amount_CHF = 0;
            let amount_CHW = 0;
            let total_amount = 0;

            let auxAmountCHF = 0;
            let auxAmountCHW = 0;

            let priceProduct = 0;

            for (let i = 0; i < infoProducts?.length; i++) {
              priceProduct = Number(infoProducts[i].grossPriceProduct);

              if (this.userhasWir) {
                auxAmountCHF = Number(infoProducts[i].amount_CHF);
                auxAmountCHW = Number(infoProducts[i].amount_CHW);

                //if the user doesnt input a percentage wir we need set by the default  CHF equal to grossPrice product
                if (!infoProducts[i].percentage_wir) {
                  amount_CHF += priceProduct;
                } else {
                  amount_CHF += isNaN(auxAmountCHF) ? 0 : auxAmountCHF;
                }

                amount_CHW += isNaN(auxAmountCHW) ? 0 : auxAmountCHW;
              } 
            }

            if (this.userhasWir) {
              amount_CHF = this.formatNumber(String(amount_CHF));
              amount_CHW = this.formatNumber(String(amount_CHW));
            }

            total_amount = this.calculateInvoiceAmount(infoProducts, currentRecord.billing_round_difference);

            let newDebtorObject = {
              id_b: currentRecord.id,
              id: i + 1,
              salutation: currentRecord.salutation,
              salutationSearch: this.$t(`Debtors.SalutationsReverse`)[
                currentRecord.salutation
              ],
              name: currentRecord.name,
              last_name: currentRecord.last_name,
              address_suffix: currentRecord.address_suffix,
              amount_CHF,
              amount_CHW,
              invoice_amount: total_amount,
              street: currentRecord.street,
              number: currentRecord.number,
              zip: currentRecord.zip,
              city: currentRecord.city,
              country: currentRecord.country,
              currency: currentRecord.currency,
              products_json: currentRecord.products_JSON,
              language: currentRecord.language,
              languageSearch: this.$t("app.languages")[currentRecord.language],
              reference: currentRecord.reference,
              message: currentRecord.message,
              client_number: currentRecord.client_number,
              member_category: currentRecord.member_category,
              email: currentRecord.email,
              client_reference: currentRecord.client_reference,
              qr_status: currentRecord.qr_bill_status,
              first_warning: currentRecord.first_warning,
              second_warning: currentRecord.second_warning,
              last_modified_date: this.fromDateToString(
                currentRecord.last_modified_date
              ),
              billing_information: {
                invoice_no: currentRecord.billing_invoice_number,
                invoice_date: currentRecord.billing_invoice_date,
                vat_date: currentRecord.billing_vat_date,
                client_reference: "",
                UID: "",
                VAT: currentRecord.billing_vat,
                discount: currentRecord.billing_discount,
                payment_deadline: currentRecord.billing_payment_deadline,
                round_difference: currentRecord.billing_round_difference,
                // total_amount_bexio: currentRecord.billing_bexio_total_amount
              },
            };
            newDebtorObject.error =
              this.singleDebtorValidation(newDebtorObject);

            auxArray.push(newDebtorObject);
          }
          this.debtors = auxArray;
        })
        .then(() => {
          //We return the following variable but now false
          return (this.loadingState = false);
        })
        .catch(this.$handleCatch);
    },
    fromDateToString(date) {
      if (!date) return "";
      let newDate = new Date(date);
      if (!newDate) return "";
      let newDay = `${newDate.getDate()}`.padStart(2, "0");
      let newMonth = `${newDate.getMonth() + 1}`.padStart(2, "0");
      let newYear = `${newDate.getFullYear()}`.padStart(2, "0");
      let newHour = `${newDate.getHours()}`.padStart(2, "0");
      let newMinutes = `${newDate.getMinutes()}`.padStart(2, "0");
      return `${newYear}/${newMonth}/${newDay} ${newHour}:${newMinutes}`;
    },
    customSortDebtors(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a, b) => {
          if (currentIndex === "last_modified_date") {
            if (currentIsDesc)
              return (
                new Date(`${b[currentIndex]} UTC`) -
                new Date(`${a[currentIndex]} UTC`)
              );
            return (
              new Date(`${a[currentIndex]} UTC`) -
              new Date(`${b[currentIndex]} UTC`)
            );
          } else if (currentIndex == "id") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let aElement = a[currentIndex] ? `${a[currentIndex]}` : "";
            let bElement = b[currentIndex] ? `${b[currentIndex]}` : "";
            if (currentIsDesc)
              return aElement
                .toLowerCase()
                .localeCompare(bElement.toLowerCase());
            return bElement.toLowerCase().localeCompare(aElement.toLowerCase());
          }
        });
      }
      return items;
    },
    async deleteItem(item) {
      //Variable that asks the user if they are sure they want to delete that debitor
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          this.$t("Debtors.delete_debtor"),
          {
            color: "red",
          }
        )
        .then(async (confirmation) => {
          if (confirmation) {
            //if you click on the word yes then go to the next fetch
            //which sends the id of the selected debitro in your body
            await this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/user/debtor`,
              {
                method: "DELETE",
                body: JSON.stringify({ id: item.id_b }),
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then((response) => {
                if (response) {
                  if (response.status == 200) {
                    let currentItemIndex = this.debtors.indexOf(item);
                    let debtorsCopy = [...this.debtors];
                    debtorsCopy.splice(currentItemIndex, 1);
                    this.debtors = [...debtorsCopy];
                  }
                  return this.$store.commit(`global/setSnackbar`, {
                    message: response.message,
                  });
                } else {
                  return this.$store.commit("global/setSnackbar", {
                    message: this.$t("StepByStep.invalid_response"),
                  });
                }
              })
              .catch(this.$handleCatch);
          }
        });
    },
    editItem(item) {
      this.currentItemIndex = this.debtors.indexOf(item);
      Object.assign(this.item, item);
      this.item.billing_information.UID = this.$store.state.User.username.UID;
      this.item.billing_information.client_reference =
        this.$store.state.User.username.reference;
      let newArray = new Array();
      if (item.products_json) {
        newArray = [...JSON.parse(item.products_json)];
        if (this.userhasWir) {
          newArray = newArray.map((element) => {
            if (!element.percentage_wir) {
              return {
                ...element,
                percentage_wir: 0,
                amount_CHF: element.grossPriceProduct,
                amount_CHW: 0,
              };
            }
            return element;
          });
        }
        newArray.forEach((element, index) => (element.id = index + 1));
      }
      this.showDialogFlag = true;
      return this.$nextTick(() => {
        // this.$refs.debtorProductTable.total_amount_bexio = Number(item.billing_information.total_amount_bexio);
        this.$refs.debtorProductTable.billing_round_difference = item.billing_information.round_difference;
        this.$refs.debtorProductTable.productItems = [...newArray];
      });
    },
    hideDialog() {
      this.showDialogFlag = false;
    },
    async saveDialogChanges() {

      if (!this.$refs.debtorForm.validate()) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }

      // This is a extra validation, is a validation if there's a invalid
      // character when the user type the info or import the data

      // Create an array with the values that we are going to examinate if don't have invalid characters
      let examinateValues = [
        this.item.name,
        this.item.last_name,
        this.item.address_suffix,
        this.item.street,
        this.item.city,
        this.item.client_number,
        this.item.message,
        this.item.client_reference,
        this.item.billing_information.UID,
        this.item.billing_information.invoice_no,
      ];

      this.$refs.debtorProductTable.productItems.forEach((element) =>
        examinateValues.push(element.name)
      );

      // // There some cases where client_reference does not exist due to the properties of the account, for it we check if it exists,
      // // in case it does, we add it to the array
      if (this.item.billing_information.client_reference)
        examinateValues.push(this.item.billing_information.client_reference);

      // Validate if the user information don't have any invalid character
      if (validateCharac(examinateValues).status) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("Globals.error_character"),
        });
      }

      let validation = this.debtorValidations();



      if (typeof validation === "string") {
        return this.$store.commit(`global/setSnackbar`, {
          message: validation,
        });
      }
      if (!validation) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }
      this.showDialogSaveLoader = true;
      this.$refs.debtorProductTable.productItems.forEach(
        (element) => (element.id = undefined)
      );
      let currentSalutation = this.item.salutation || 'keine';
      if (currentSalutation) {
        if (typeof currentSalutation === "object") {
          currentSalutation = this.item.salutation.value;
        }
      }
      let currentLanguage = this.item.language;
      if (currentLanguage) {
        if (typeof currentLanguage === "object") {
          currentLanguage = this.item.language.value;
        }
      }
      console.log(this.$refs.debtorProductTable.productItems);

      let updatedDebtor = {
        salutation: currentSalutation,
        name: this.item.name,
        last_name: this.item.last_name,
        address_suffix: this.item.address_suffix,
        street: this.item.street,
        number: this.item.number,
        zip: this.item.zip,
        city: this.item.city,
        country: this.item.country,
        currency: this.item.currency,
        products_json: JSON.stringify(
          this.$refs.debtorProductTable.productItems
        ),
        language: currentLanguage,
        reference: this.item.reference ? this.item.reference.toUpperCase() : "",
        message: this.item.message,
        client_number: this.item.client_number,
        member_category: this.item.member_category,
        email: this.item.email,
        qr_status: this.item.qr_status ? this.item.qr_status : "",
        first_warning: this.item.first_warning ? this.item.first_warning : "",
        second_warning: this.item.second_warning
          ? this.item.second_warning
          : "",
        client_reference: this.item.client_reference,
        billing_information: {
          invoice_no: this.item.billing_information.invoice_no,
          invoice_date: this.item.billing_information.invoice_date,
          vat_date: this.item.billing_information.vat_date,
          VAT: this.item.billing_information.VAT,
          discount: this.item.billing_information.discount,
          payment_deadline: this.item.billing_information.payment_deadline,
        },
      };

      let products = this.$refs.debtorProductTable.productItems;
      let amountsCHF = products.map((element) => {
        let number = 0;
        if (element.percentage_wir && this.userhasWir) {
          number = Number(element.amount_CHF);
        } else {
          number = Number(element.grossPriceProduct);
        }

        return isNaN(number) ? 0 : number;
      });

      let amount_CHF = amountsCHF.reduce((prev, current) => {
        return (current || 0) + (prev || 0);
      }, 0);
      updatedDebtor.amount_CHF = amount_CHF == 0 ? 0 : amount_CHF.toFixed(2);
      let amount_CHW = 0;
      if (this.userhasWir) {
        let amountsCHW = products.map((element) => {
          let number = Number(element.amount_CHW);
          return isNaN(number) ? 0 : number;
        });

        amount_CHW = amountsCHW.reduce((prev, current) => {
          return (current || 0) + (prev || 0);
        }, 0);
        updatedDebtor.amount_CHW = amount_CHW == 0 ? 0 : amount_CHW.toFixed(2);
      }

      if (this.userhasWir)
        updatedDebtor.invoice_amount = (amount_CHF + amount_CHW).toFixed(2);
      else updatedDebtor.invoice_amount = amount_CHF.toFixed(2);

      if (
        this.currentItemIndex !== undefined &&
        this.currentItemIndex !== null
      ) {
        updatedDebtor.id_b = this.item.id_b;
        let response = await this.createUpdateSingleDebtor(updatedDebtor);

        if (response) {
          updatedDebtor.id = this.item.id;
          updatedDebtor.last_modified_date = this.fromDateToString(
            response.LMD
          );
          updatedDebtor.languageSearch =
            this.$t("app.languages")[updatedDebtor.language];
          updatedDebtor.salutationSearch = this.$t(
            "Debtors.SalutationsReverse"
          )[updatedDebtor.salutation];

          let errorFlag = this.singleDebtorValidation(updatedDebtor);
          updatedDebtor.error = errorFlag;
          let debtorsUpCopy = this.debtors;
          Object.assign(debtorsUpCopy[this.currentItemIndex], updatedDebtor);

          this.debtors = [...debtorsUpCopy];

          if (!this.filtersNewValidation(updatedDebtor)) {
            let debtorsDeCopy = this.debtors;
            debtorsDeCopy.splice(this.currentItemIndex, 1);
            this.debtors = [...debtorsDeCopy];
          }

          if (this.debtorsAux.length > 0) {
            let getAuxCurrentIndex = this.debtorsAux.findIndex(
              (element) => element.id === this.item.id
            );
            if (
              getAuxCurrentIndex !== undefined &&
              getAuxCurrentIndex !== null
            ) {
              Object.assign(this.debtorsAux[getAuxCurrentIndex], updatedDebtor);
            }
          }
        }
      } else {
        let newId = 0;
        let tempId = null;
        if (this.debtors.length > 1) {
          let getHighestId = await this.debtors.sort((a, b) => b.id - a.id);
          tempId = getHighestId ? getHighestId[0].id : null;
        } else if (this.debtors.length == 1) {
          let getHighestId = this.debtors[0];
          tempId = getHighestId ? getHighestId.id : null;
        }
        newId = tempId ? tempId + 1 : 1;
        let response = await this.createUpdateSingleDebtor(updatedDebtor);
        if (response) {
          updatedDebtor.id = newId;
          updatedDebtor.id_b = response.id;
          updatedDebtor.last_modified_date = this.fromDateToString(
            response.LMD
          );
          updatedDebtor.languageSearch =
            this.$t("app.languages")[updatedDebtor.language];
          updatedDebtor.salutationSearch = this.$t(
            "Debtors.SalutationsReverse"
          )[updatedDebtor.salutation];
          let errorFlag = this.singleDebtorValidation(updatedDebtor);
          updatedDebtor.error = errorFlag;
          if (this.filtersNewValidation(updatedDebtor)) {
            let debtorsAux = this.debtors;
            debtorsAux.push(updatedDebtor);

            this.debtors = [...debtorsAux];
          }
          if (this.debtorsAux.length > 0) {
            await this.debtorsAux.push(updatedDebtor);
          }
        }
      }

      this.$refs.debtorProductTable.productItems = new Array();
      Object.assign(this.item, this.default_item);
      this.showDialogSaveLoader = false;
      this.showDialogFlag = false;
    },
    async createUpdateSingleDebtor(item) {
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/user/debtor`,
          {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );
        if (response) {
          if (response.status == 200) {
            this.$store.commit("global/setSnackbar", {
              message: response.message,
            });
            return {
              id: response.id,
              LMD: response.LMD,
            };
          } else {
            this.$store.commit("global/setSnackbar", {
              message: response.message,
            });
            return undefined;
          }
        } else {
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("StepByStep.invalid_response"),
          });
        }
      } catch (err) {
        this.$handleCatch;
      }
    },
    debtorValidations() {
      if (this.$refs.debtorProductTable.productItems.length < 1) {
        return this.$t("snack_confirm.product_not_added");
      }
      if (this.$refs.debtorProductTable.productItems.length > 15) {
        return this.$t("snack_confirm.list_products_length");
      }

      let productsWithLongNames = this.$refs.debtorProductTable.productItems.some((element) => {
        return element.name.length > 40;
      });
      if (productsWithLongNames) {
        return this.$t("snack_confirm.product_name_long")
      }

      return true;
    },


    //Genera los filtros de errores
    singleDebtorValidation(debtor) {
      /*let examinateValues = [this.item.name, this.item.last_name, this.item.address_suffix, this.item.street, this.item.city, this.item.client_number,
                             this.item.message, this.item.client_reference, this.item.billing_information.UID, this.item.billing_information.invoice_no];*/

      let examinateValues = [
        debtor.name,
        debtor.last_name,
        debtor.address_suffix,
        debtor.street,
        debtor.city,
        debtor.client_number,
        debtor.message,
        debtor.client_reference,
        debtor.billing_information.UID,
        debtor.billing_information.invoice_no,
      ];

      let validation1 = this.rules.isEmpty(debtor.name) === true ? true : false;
      let validation2 =
        this.rules.isEmpty(debtor.street) === true ? true : false;
      let validation4 = this.rules.isEmpty(debtor.zip) === true ? true : false;
      let validation5 = this.rules.isEmpty(debtor.city) === true ? true : false;
      let validation6 =
        this.rules.isEmpty(debtor.country) === true ? true : false;
      let validation7 =
        this.rules.isEmpty(debtor.currency) === true ? true : false;
      let validation8 =
        this.rules.isEmpty(debtor.language) === true ? true : false;
      let validation9 = debtor.products_json
        ? JSON.parse(debtor.products_json).length > 0
        : false;
      let validation10 = debtor.products_json
        ? JSON.parse(debtor.products_json).length <= 15
        : false;
      let validation11 =
        this.rules.isEmpty(debtor.billing_information.invoice_date) === true
          ? true
          : false;
      let validation14 = validateCharac(examinateValues).status;

      let hasProductWithInvalidPercentege = false;
      let productsWithLongNames = false
      if (debtor.products_json) {
        const infoProducts = JSON.parse(debtor.products_json);
        hasProductWithInvalidPercentege = infoProducts.some((element) => {
          return (
            element.percentage_wir &&
            (element.percentage_wir > 100 || element.percentage_wir < 0)
          );
        });

        productsWithLongNames = infoProducts.some((element) => {
          return element.name.length > 40;
        });
      }

      let validation12 = true;
      if (this.userhasWir) validation12 = !hasProductWithInvalidPercentege;

      let validation13 = true;
      if (debtor.reference && debtor.reference.length > 0) {
        const typeReference = detector.detectTypeReference(
          debtor.reference.trim()
        );
        validation13 = typeReference == "QRR" || typeReference == "SCOR";
      }

      return !(
        validation1 &&
        validation2 &&
        validation4 &&
        validation5 &&
        validation6 &&
        validation7 &&
        validation8 &&
        validation9 &&
        validation10 &&
        validation11 &&
        validation12 &&
        validation13 &&
        !validation14 && !productsWithLongNames
      );
    },
    async updateStatusDebtor(item) {
      this.setLoadingDisabledStateStatus = true;
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/user/debtor/status`,
          {
            method: "POST",
            body: JSON.stringify({
              id: item.id_b,
              qr_status: item.qr_status,
              first_warning: item.first_warning,
              second_warning: item.second_warning,
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );
        if (response) {
          if (response.status == 200) {
            let getCurrentIndex = this.debtors.indexOf(item);
            let newLMD = this.fromDateToString(response.LMD);
            let debtorsAux = this.debtors;
            debtorsAux[getCurrentIndex].last_modified_date = newLMD;
            this.debtors = debtorsAux;
            if (!this.filtersNewValidation(item)) {
              let debtorsDeAux = this.debtors;
              debtorsDeAux.splice(getCurrentIndex, 1);
              this.debtors = debtorsDeAux;
            }
            if (this.debtorsAux.length > 0) {
              let getAuxCurrentIndex = this.debtorsAux.indexOf(item);
              this.debtorsAux[getAuxCurrentIndex].last_modified_date = newLMD;
            }
          } else {
            this.$store.commit(`global/setSnackbar`, {
              message: response.message,
            });
          }
        } else {
          this.$store.commit(`global/setSnackbar`, {
            message: this.$t("StepByStep.invalid_response"),
          });
        }
        return (this.setLoadingDisabledStateStatus = false);
      } catch (err) {
        return this.$store.commit(`global/setSnackbar`, { message: err });
      }
    },
    filtersNewValidation(item) {
      let selectedStatus = this.statusSelections.status.selected;
      let selectedFirst = this.statusSelections.first.selected;
      let selectedSecond = this.statusSelections.second.selected;
      let validation1 = true;
      let validation2 = true;
      let validation3 = true;
      //-------- Status ---------//
      if (selectedStatus !== undefined && selectedStatus !== null)
        validation1 = item.qr_status == selectedStatus;
      if (selectedStatus === "" && item.qr_status === null) validation1 = true;
      //-------------------------//
      //-------- First ----------//
      if (selectedFirst !== undefined && selectedFirst !== null)
        validation2 = item.first_warning == selectedFirst;
      if (selectedFirst === "" && item.first_warning === null)
        validation2 = true;
      //-------------------------//
      //--------- Second --------//
      if (selectedSecond !== undefined && selectedSecond !== null)
        validation3 = item.second_warning == selectedSecond;
      if (selectedSecond == "" && item.second_warning === null)
        validation3 = true;
      //-------------------------//
      return validation1 && validation2 && validation3;
    },
    calculateItemText(item) {
      if (item.text.length > 13) return `${item.text.substring(0, 13)}...`;
      return item.text;
    },
    setItemsByLanguage(item) {
      item.languageSearch = this.$t("app.languages")[item.language];
      item.salutationSearch = this.$t("Debtors.SalutationsReverse")[
        item.salutation
      ];
      return item;
    },
    async openLoginBexio() {
      // this.dialogbexio = true;
      this.loadingBtn = true;
      let response = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bexio/login`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      );
      const test = window.open(
        response.authUrl,
        "authWindow",
        "width=600,height=auto,resizable=no"
      );
      // const authWindow = window.open('${authUrl}', 'authWindow', 'width=600,height=600,left=200,top=200,resizable=yes,scrollbars=yes');

      // console.log("response");
    },
    handleMessage(event) {
      this.loadingBtn = false;
      console.log("Message received:", event.data.response);
      console.log("tye", typeof event.data.response);
      if (
        typeof event.data.response === "object" &&
        event.data.response.status === 200
      ) {
        this.connectBexio = false;
        this.actionsBexio = true;
        console.log("refresh page o el btn desabilitarlo");
      }
    },
    async connection() {
      let bexioUser = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bexio/user`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      );

      if (bexioUser.status != 200) {
        // this.connectBexio = true;
        console.log("1");
        return;
      }

      let bexioConnection = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bexio/connection`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      );
      console.log("baxiosconnection", bexioConnection);
      if (bexioConnection.status == 400) {
        // this.connectBexio = true;
        console.log(2);
        return;
      } else if (bexioConnection.status == 200) {
        // this.connectBexio = false;
        this.actionsBexio = true;
        console.log(3);
        return;
      }
      console.log(4);
      return;
    },
    async OpenBexioDialog() {
      this.dialogbexio = true;
    },
    hideImportDialog() {
      this.dialogbexio = false;
    },
    hiddeDialogResponse() {
      this.dialogResponseImport.show = false;
    },
    async saveImportDialogChanges() {
      this.showImportDialogSaveLoader = true;
      const body = {
        from: this.dateFrom,
        until: this.dateUntil,
        status: this.statusInvoices,
      };
      try {
        let importInvoices = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/bexio/import`,
          {
            method: "POST",
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
            body: JSON.stringify(body),
          }
        );

        this.showImportDialogSaveLoader = false;
        this.hideImportDialog();
        this.dialogResponseImport.show = true;

        if (importInvoices.status == 200) {
          this.dialogResponseImport.status = 200;
          this.loadDebtorsList();
          return;
        }

        if (importInvoices.status == 204) {
          this.dialogResponseImport.status = 204;
          return;
        }
      } catch (err) {
        this.hideImportDialog();
        this.dialogResponseImport.show = true;
        this.dialogResponseImport.status = 0;
        return res.status(200).json({
          status: 400,
          message: err,
        });
      }
    },
    getDefaultDateFrom() {
      const today = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(today.getMonth() - 1);
      return lastMonth.toISOString().substr(0, 10); // Formato 'YYYY-MM-DD'
    },
    getDefaultDateUntil() {
      const today = new Date();
      return today.toISOString().substr(0, 10); // Formato 'YYYY-MM-DD'
    },
  },
};
</script>

<style scope>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Apply fixed for column 1 */
.table-debtors table>tbody>tr>td:nth-child(1),
.table-debtors table>thead>tr>th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background: white;
}

.table-debtors table>thead>tr>th:nth-child(1) {
  z-index: 1;
}

/* Apply fixed for column 2 */
.table-debtors table>tbody>tr>td:nth-child(2),
.table-debtors table>thead>tr>th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 56px;
  z-index: 1;
  background: white;
}

.table-debtors table>thead>tr>th:nth-child(2) {
  z-index: 1;
}

/* Apply fixed for column 3 */
.table-debtors table>tbody>tr>td:nth-child(3),
.table-debtors table>thead>tr>th:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 126px;
  z-index: 1;
  background: white;
}

.table-debtors table>thead>tr>th:nth-child(3) {
  z-index: 1;
}

/* Apply fixed for column 4 */
.table-debtors table>tbody>tr>td:nth-child(4),
.table-debtors table>thead>tr>th:nth-child(4) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 266px;
  z-index: 1;
  background: white;
}

.table-debtors table>thead>tr>th:nth-child(4) {
  z-index: 1;
}


.container-debtors {
  height: 88vh !important;
  overflow: hidden !important;
}

/*We create a CSS that dissapear the webpage scrollbar, so that only makes the table scrollbar appears */
html::-webkit-scrollbar {
  display: none;
}

/* this style is for scroll bar  */

#wrapper2 {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

#div2 {
  height: 1px;
  overflow: scroll;
}

.width-scroll {
  width: 3002px;
}

.width-scroll-WIR {
  width: 3275px;
}

/*                 */

.searcontent {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .backColor{
  background: red;
 } */

.containerBtnBexio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
}

.btnBexio {
  font-family: "Poppins", sans-serif;
  text-transform: none !important;
  width: 65%;
  background: #95c11f !important;
  color: #fff !important;

  white-space: normal;
  line-height: 1.5;
  /* Ajusta según tu preferencia */
  padding: 8px 16px !important;
}

.heihtBtn {
  height: 50px !important;
}

.toolBarBexio {
  background: #0d2f3b !important;
}

.textBexio {
  color: #95c11f;
  font-size: 2rem !important;
}
</style>

<template>
  <div>
      <v-form ref="form">
        <v-card  shaped class="mx-auto my-16" max-width="674">
           <div   class="d-flex justify-center">
            <v-img
          max-width="200"
          max-height="300"
          :src="images.logo"
    > <div class="fill-height repeating-gradient"></div></v-img>
    </div>
        <v-card-title class="d-flex justify-center"> <h2> {{$t('Recover.recover_password')}} </h2> </v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle  class="d-flex justify-center">
         <h2>  {{$t('Recover.recover_subtitle')}}</h2>
        </v-card-subtitle>
        <v-spacer> </v-spacer>
        <v-card-text>
            <v-text-field 
           outlined
           autofocus
         class="pa-5 ma-8"
           dense
           :label="$t('Recover.email_field')"
           v-model="getemail"
           :rules="[rules.email,rules.notEmpty]" 
            >
            </v-text-field>
        </v-card-text>
        <v-card-actions class="pr-6" >
        <v-spacer></v-spacer>
            <v-btn text 
            :disabled="disabledbtn"
            :color="$vuetify.theme.themes.dark.green.secondary"
            min-width="80"
            max-width="80"
            dark
            @click="recovery"
            dense
           :loading="DOMElements.recoverpass.loading"
            >    <v-icon left>
               mdi-magnify
             </v-icon>{{$t('Recover.btn_find')}}</v-btn
            >
            <v-btn text @click="redirect">{{$t('Recover.btn_cancel')}}</v-btn>
        </v-card-actions>
        </v-card>
      </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return{
      images: {
        logo: require('../../../../assets/images/default/qrLogoComplete.png')
      },
      getemail:"",
      disabledbtn: false,
      DOMElements: {
        recoverpass: {
          loading: false
        }
      },
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("rules.email");
        },
        notEmpty: value => {
          return !!value || this.$t("rules.notempty")
       }             
      }
    };
  },
  mounted(){
    //This commit  changes the title on the dashboard
    this.$store.commit("global/setPageTitle", this.$t("Recover.recover_password"));
    window.addEventListener('keypress',this.onKeypress,false);
  },
  watch:{
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t("Recover.recover_password"))
    }
  },
  computed:{
    locale(){
      return this.$store.state.User.language;
    },  
  },
  destroyed(){
    window.removeEventListener('keypress',this.onKeypress,false);
  },
  methods : {
    recovery(){
      this.DOMElements.recoverpass.loading = true;
      if (!this.$refs.form.validate()) {
        this.DOMElements.recoverpass.loading = false;
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("snack_confirm.invalid_inputs")
        });
      } 
      else{
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/email/recover?email=${this.getemail}&lang=${this.$i18n.locale}`, {
        method: "GET",
      })
          .then((result) => {
            return result.json();
          })
          .then((response) => {
         //This is for when there is no user with that email, send a message saying that the email doesn't exist.
        if(response.status != 200){
          this.DOMElements.recoverpass.loading = false;
        return this.$store.commit("global/setSnackbar", {
          message: response.message   });
          }
        else
          {
          //Here you can disable the button to avoid being spammed.
          this.disabledbtn= true;
          this.DOMElements.recoverpass.loading = false;
          this.$router.push(sessionStorage.getItem('userPathDesired') || '/login');
          return this.$store.commit("global/setSnackbar", {
          message: response.message   });
          }
        })
      }//end else
     },//end recovery

     redirect(){
       //Redirect to login if you want to cancel ///
          this.$router.push(sessionStorage.getItem('userPathDesired') || '/login');
         },
         //*********************** */
       
    onKeypress(e){
      if(e.key === "Enter"){
        e.preventDefault();
        return this.recovery();
      }
    }
  
    }/*---------------------------------------------------------------*/
}
</script>
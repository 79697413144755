<template>
  <v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :top="top"
        :centered="centered"
        :left="left"
        :right="right"
        :bottom="bottom"
        :multi-line="multiline" 
        >
        {{ message }} 
        <v-btn
            color="blue"
            text
            @click="snackbar = false"
        >
            {{$t("Globals.btn_close")}}
        </v-btn>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :persistent="persistent"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">
            
          </v-card-title>
          <v-card-text style="white-space: normal ;" >
            <p>{{ message }}<p>
            <p v-if="optionalMessage">{{ optionalMessage }} </p>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              {{$t("qrGenerator.windowsCredit.btn_agree")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      message: '',
      optionalMessage:'',
      timeout: 5000,
      left: false,
      right: false,
      bottom: false,
      centered: false,
      top: false,

      multiline: false,
      persistent: false,

      style: {
        message: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      snackbarOptions: 'global/snackbar',
      snackbarStatus: 'global/snackbarStatus',
      dialogStatus: 'global/dialogStatus'
    }),

    dialog: {
      get() {
        return this.dialogStatus
      },
      set(value) {
        this.$store.state.global.dialogStatus = value
      }
    },
    snackbar: {
      get() {
        return this.snackbarStatus;
      },
      set(value) {
        this.$store.state.global.snackbarStatus = value;
      }
    }
  },
  created() {
    this.$store.watch(state => state.global.snackbar.message, (value, old) => {
      if(value !== '') {
        if(this.snackbarOptions.type == 'snackbar') {
          this.snackbar = true;
        }

        if(this.snackbarOptions.type == 'dialog') {
          this.dialog = true;
        }

        if(!this.snackbarOptions.type) {
          if(value.length > 35) {
            this.dialog = true;
          } else {
            this.snackbar = true;
          }
        }

        Object.assign(this, this.snackbarOptions);
        this.$store.commit('global/setSnackbar', { message: '' }); 
      }
    })

    this.$store.watch(state => state.global.snackbarStatus, (value, old) => {
      if(!value) {
        this.resetSnackbar();
      }
    })

    this.$store.watch(state => state.global.dialogStatus, (value, old) => {
      if(!value) {
        this.resetSnackbar();
      }
    })
  },
  methods: {
    resetSnackbar() {
      this.snackbarOptions.left       = false;
      this.snackbarOptions.right      = false;
      this.snackbarOptions.bottom     = false;
      this.snackbarOptions.centered   = false;
      this.snackbarOptions.top        = false;
      this.snackbarOptions.multiline  = false;
      this.snackbarOptions.persistent = false;
      this.snackbarOptions.type       = '';
    }
  }
}
</script>
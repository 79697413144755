<template>
    <v-container>
        <v-row>
            <v-col sm="6" lg="6" cols="12" >
                <v-card height="100%" >
                    <v-card-title>{{ $t("qrGenerator.payableTo.main_title") }}</v-card-title>
                    <v-card-text>
                        <v-form ref="form.creditor">
                            <qr-premium-creditor-selection ref="creditor.selection" />
                            <qr-form-creditor ref="qrFormCreditor" :isPremium="true" />
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col sm="6" lg="6" cols="12" >
                <v-card height="100%" >
                    <v-form 
                        ref="form.debtor"
                        onSubmit="return false;"
                    >
                        <qr-premium-debtor-selection ref="debtor.selection" />
                        <qr-form-debtor @qrWithWir=" (flag) => { qrWir = flag } "   @qrBothCurrency =" (flag) => { qrWithBothCurrency = flag } " ref="qrFormDebtor" />
                    </v-form>
                </v-card>
            </v-col>
            <v-col cols="12" >
                <qr-form-billing ref="qrBilling" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-form ref="form.productTable">
                        <qr-product-table ref="qrProductTable" />
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="qrWithBothCurrency &&  userHasWir" cols="12">  <!-- &&  showFieldsWir -->
                <qr-card />
            </v-col>
            <v-col cols="12" >
                <qr-card :withWir="qrWir && userHasWir"  /> <!-- && showFieldsWir  -->
            </v-col>
        </v-row>
        <v-row>
            <qr-buttons ref="qrButtons" />
        </v-row>
        <v-row>
            <window-credits
            :key="updateBuyCredits"
            v-bind:propsMap="{
                popup: popup,
                price: price,
                origin: 'single',
                creditsinsuf: creditsinsuf,
                titlepopup: titlepopup,
                subtitlepopup: subtitlepopup,
                remainingcredits: remainingcredits,
                total: total
            }"
            />
        </v-row>
    </v-container>
</template>

<script>
import qrCardVue from '../qr.card.vue';
import qrFormBillingVue from '../qr.form.billing.vue';
import qrFormCreditorVue from '../qr.form.creditor.vue';
import qrFormDebtorVue from '../qr.form.debtor.vue';
import qrFormProductVue from '../qr.form.product.vue';
import qrPremiumButtonsVue from './qr.premium.buttons.vue';
import qrPremiumCreditorSelectionVue from './qr.premium.creditor.selection.vue';
import qrPremiumDebtorSelectionVue from './qr.premium.debtor.selection.vue';
import WindowCredits from "../../globals/WindowCredits.vue";
import { mapGetters, mapState } from 'vuex';
import { createHelpers } from "vuex-map-fields";
const { mapFields } = createHelpers({
  getterType: "QR/getField",
  mutationType: "QR/updateField",
});


export default {
    components: {
        'qr-form-creditor': qrFormCreditorVue,
        'qr-form-debtor'  : qrFormDebtorVue,
        'qr-form-billing' : qrFormBillingVue,
        'qr-card'         : qrCardVue,
        'qr-buttons': qrPremiumButtonsVue,
        'qr-product-table': qrFormProductVue,
        'qr-premium-creditor-selection': qrPremiumCreditorSelectionVue,
        'qr-premium-debtor-selection': qrPremiumDebtorSelectionVue,
        'window-credits': WindowCredits,
    },
    data() {
        return {
            updateBuyCredits: 0,
            popup: false,
            price: '',
            creditsinsuf: false,
            titlepopup: '',
            subtitlepopup: '',
            remainingcredits: '',
            total: '',
            qrWithBothCurrency:false,
            qrWir:false,
            booleanexit: true,
        }
    },
    created() {
        this.$store.state.AI.enableBilling = false;
    },
    mounted() {
        //This commit  changes the title on the dashboard
        this.$store.commit("global/setPageTitle", "QR Generator");
        this.init();
        this.setInputPremiumReference();
    },
    destroyed(){
        this.debtor = {
            name: "",
            last_name: '',
            address: {
                street: '',
                number: '',
                zip: '',
                city: "",
                country: ""
            },
        },
        this.message = '';
        this.amount = 0;
        this.percentageWir = 0;
        this.currency = 'CHF'
    },
    watch:{
        locale(newValue,oldValue){
          this.$store.commit("global/setPageTitle","QR Generator")
        },
        qrWithBothCurrency(newValue,oldValue){
            this.$store.commit("QR/setBothCurrencies",newValue);
        }
    },
    computed: {
        locale(){
            return this.$store.state.User.language;
        },
        ...mapGetters({
            creditor: 'User/creditor',
            user: 'User/user',
        }),
        ...mapFields({
          QRRCheckboxs: 'checkboxs.QRR',
          SCORCheckboxs: 'checkboxs.SCOR',
          QRRFields: 'automaticReferences.QRR',
          SCORFields: 'automaticReferences.SCOR'
        }),

    
       percentageWir:{
            set(value){
                return this.$store.commit("QR/setPercentage", value ) ;
            },
            get(){
                return this.$store.state.QR.wirPercentage;
            }
        },

        /*showFieldsWir:{
           get(){
              return this.$store.state.QR.showFieldsWir;
           },
        },*/

        userHasWir(){
             return this.$store.state.User.wir_active;
        },

        debtor:{
            get(){
                return this.$store.state.QR.debitor;
            },
            set(value){
                this.$store.state.QR.debitor = value;
            }
        },
        message:{
            get(){
                return this.$store.state.AI.unstructuredMessage;
            },
            set(value){
                this.$store.state.AI.unstructuredMessage =  value;
            }
        },
        SCORTabSelected: {
          get() {
            return this.$store.state.QR.SCORTabSelected
          },
          set(value) {
            this.$store.state.QR.SCORTabSelected = value;
          }
        },
        QRRTabSelected: {
          get() {
            return this.$store.state.QR.QRRTabSelected
          },
          set(value) {
            this.$store.state.QR.QRRTabSelected = value
          }
        },
        amount: {
            get() {
                return this.$store.state.QR.amount;
            },
            set(amount) {
                this.$store.commit("QR/setAmount", amount);
            }
        },

        amountStatus: {
            get() {
                return this.$store.state.QR.stateAmount;
            },
            set(state) {
                this.$store.commit("QR/setStateAmount", state);
            }
        },

        currency: {
            get() {
                return this.$store.state.QR.currency;
            },
            set(currency) {
                this.$store.commit("QR/setCurrency", currency);
            }
        },
        referenceMask: {
          get() {
            return this.$store.state.User.creditor.referenceMask;
          },
          set(value) {
            this.$store.commit('User/setReferenceMask', value);
          }
        },
        referenceCounter: {
            get() {
                return this.$store.state.User.creditor.referenceCounter;
            },
            set(value) {
                this.$store.commit('User/setReferenceCounter', value)
            }
        },
        reference: {
          get() {
            return this.$store.state.QR.reference;
          },
          set(reference) {
            this.$store.commit("QR/setReference", reference);
          }
        },
    },
    methods: {
        validateForms() {
            this.$refs["form.creditor"].validate();
            this.$refs.qrFormDebtor.$refs.form.validate();
            this.$refs.qrBilling.$refs.form.validate();
            let fileValidation = true;
            if(this.$refs.qrProductTable.tab == 'tab-2'){
                fileValidation = this.$refs.qrProductTable.$refs.form.validate();
            }
            return (
                this.$refs["form.creditor"].validate()
                && this.$refs.qrFormDebtor.$refs.form.validate()
                && this.$refs.qrBilling.$refs.form.validate()
                && fileValidation
            )
        },
        init() {
            //Sets default values, subject to be removed
            this.creditor.typeReference        = "";
            this.$refs.qrFormDebtor.tab        = 'tab-1';
            this.$refs.qrFormDebtor.tabdebitor = 'tab-1';
            // this.amount                        = "0";
            this.currency                      = "CHF";
            // this.$store.state.AI.enableBilling = true;
            Object.assign(this.$store.state.AI.structuredMessage,{
                bill_number: undefined,
                voucherDate: undefined,
                customerReference: undefined,
                UID_Number: undefined,
                VAT_Date: { start_date: null, end_date: null },
                VAT_Details: [],
                VAT_Import: [],
                VAT_Conditions: [],
            });
            this.creditor.typeReference = 'NON';
            this.$refs.qrFormCreditor.setInputReference();
        },
        setInputPremiumReference() { 
            this.$refs.qrFormCreditor.setInputReference = () => {
                let QRRMask = "## ##### ##### ##### ##### #####";
                let SCORMask = "AA## XXXX XXXX XXXX XXXX XXXX X";
                this.QRRTabSelected = '';
                this.SCORTabSelected = '';
                this.QRRCheckboxs.date = false;
                this.SCORCheckboxs.date = false;
                this.QRRFields.date = '';
                this.SCORFields.date = '';
                if(this.$refs['creditor.selection'].select_master != 0) {
                    if (this.$store.state.User.creditor.typeReference == "QRR") {
                        this.QRRTabSelected = 'MANUAL';
                        this.SCORTabSelected = '';
                        this.referenceMask    = QRRMask;
                        this.referenceCounter = 32;
                        
                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.qr_account_wir);
                            ibansToValidate.push(this.user.qr_account);
                        }else{
                            ibansToValidate.push(this.user.qr_account);
                        }    
                        
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) != 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }    
                        }
                        
                        if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditor.account = ibansToValidate[1];
                        }else{
                            this.creditor.account = ibansToValidate[0];
                        }

                        this.$nextTick(()=>{
                            this.reference = this.$store.getters['QR/asignCorrectDebtorReference'];
                            this.QRRFields.reference = this.reference;
                            this.creditor.reference = this.reference;
                        })
                    } else if (this.$store.state.User.creditor.typeReference == "SCOR") {
                        this.SCORTabSelected = 'MANUAL';
                        this.QRRTabSelected = '';
                        this.referenceMask    = SCORMask;
                        this.referenceCounter = 25;
                        

                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.account_wir);
                            ibansToValidate.push(this.user.account);
                        }else{
                            ibansToValidate.push(this.user.account);
                        }
                                           
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) == 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }
                        }
                        


                        if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditor.account = ibansToValidate[1];
                         }else{
                            this.creditor.account = ibansToValidate[0];
                         }
                    

                        this.$nextTick(()=>{
                            this.reference = this.$store.getters['QR/asignCorrectDebtorReference'];
                            this.SCORFields.reference = this.reference;
                            this.creditor.reference = this.reference;
                        })

                    } else if (this.$store.state.User.creditor.typeReference == "NON") {
                        this.SCORTabSelected = '';
                        this.QRRTabSelected = ''
                        this.referenceMask = '';
                        this.referenceCounter = 0;
                        
                        
                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.account_wir);
                            ibansToValidate.push(this.user.account);
                        }else{
                            ibansToValidate.push(this.user.account);
                        }

                        
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) == 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }
                        }
                            
                        if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditor.account = ibansToValidate[1];
                        }else{
                            this.creditor.account = ibansToValidate[0];
                        }

                        this.$nextTick(()=>{
                            this.reference = '';
                            this.SCORFields.reference = '';
                            this.QRRFields.reference = '';
                            this.creditor.reference = '';
                        })
                    }
                } else {
                    if (this.$store.state.User.creditor.typeReference == "QRR") {
                        this.QRRTabSelected = 'MANUAL';
                        this.SCORTabSelected = '';
                        this.referenceMask = QRRMask;
                        this.referenceCounter = 32;
                        // let newIBAN = this.formatIBAN(this.qr_account);
                            
                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.qr_account_wir);
                            ibansToValidate.push(this.user.qr_account);
                        }else{
                            ibansToValidate.push(this.user.qr_account);
                        }

                        ////////////////////////////
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) != 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }
                        }

                         if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditor.account = ibansToValidate[1];
                         }else{
                            this.creditor.account = ibansToValidate[0];
                         }
                        

                        this.$nextTick(()=>{
                            this.reference = this.$store.getters['QR/asignCorrectDebtorReference'];
                            this.QRRFields.reference = this.reference;
                            this.creditor.reference = this.reference;
                        })

                    } else if (this.$store.state.User.creditor.typeReference == "SCOR") {
                        this.SCORTabSelected = 'MANUAL';
                        this.QRRTabSelected = '';
                        this.referenceMask = SCORMask;
                        this.referenceCounter = 25;
                        // let newIBAN = this.formatIBAN(this.account);
                        

                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.account_wir);
                            ibansToValidate.push(this.user.account);
                        }else{
                            ibansToValidate.push(this.user.account);
                        }   
                     
                        //////////////////////////////
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) == 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }

                        }

                        if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditopr.account = ibansToValidate[1];
                        }else 
                            this.creditor.account = ibansToValidate[0];

                        this.$nextTick(()=>{
                            this.reference = this.$store.getters['QR/asignCorrectDebtorReference'];
                            this.SCORFields.reference = this.reference;
                            this.creditor.reference = this.reference;
                        })
                    } else if (this.$store.state.User.creditor.typeReference == "NON") {
                        this.SCORTabSelected = '';
                        this.QRRTabSelected = ''
                        this.referenceMask = "";
                        this.referenceCounter = 0;
                        //let newIBAN = this.formatIBAN(this.account);
                        
                        let ibansToValidate = [];
                        if(this.userHasWir){
                            ibansToValidate.push(this.user.account_wir);
                            ibansToValidate.push(this.user.account);
                        }else{
                            ibansToValidate.push(this.user.account);
                        }
                                        
                        
                        for(let i = 0 ; i < ibansToValidate.length ; i++){
                            if (ibansToValidate[i].length > 0) {
                                if (ibansToValidate[i].charAt(5) == 3) {
                                    this.$refs.qrFormCreditor.errorState = true;
                                    this.$refs.qrFormCreditor.errorMessage = this.$t('qrGeneratorFree.miscellaneous.error_messages.typeOfReference_field');
                                    this.$refs.qrFormCreditor.typeReferenceStatus = false;
                                } else {
                                    this.$refs.qrFormCreditor.errorState = false;
                                    this.$refs.qrFormCreditor.errorMessage = "";
                                    this.$refs.qrFormCreditor.typeReferenceStatus = true;
                                }
                            }
                        }

                        if(this.userHasWir){
                            this.creditor.accountWir = ibansToValidate[0];
                            this.creditor.account = ibansToValidate[1];
                        }else 
                            this.creditor.account = ibansToValidate[0];


                        this.$nextTick(()=>{
                            this.reference = '';
                            this.SCORFields.reference = '';
                            this.QRRFields.reference = '';
                            this.creditor.reference = '';
                        })
                    }
                }
            }
        },
        tabReckoner(addExtra) {
            const { pdfFile } = this.$refs.qrProductTable;
            this.popup = false;
            //This function make de generate button execute different actions depending
            //on the tab that is selected by the user
            if(!pdfFile) {
                return this.$refs.qrButtons.generate(addExtra);
            }

            if(pdfFile) {
                return this.$refs.qrButtons.loadFile(addExtra);
            }
        },
    }
}

</script>

<style>

</style>
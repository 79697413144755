<template>
    <v-form ref="form">
      <v-card flat>
        <v-row>
          <v-col>
            <h3>{{ $t("QrSeries.step_one.select_payable") }}</h3>
            <v-select 
              :no-data-text="$t('qrGenerator.productsTable.data_available')"
              :items="$t('QrSeries.step_one.profilesItems')"
              v-model="typeOfProfile"
            ></v-select>
            <v-select
              :no-data-text="$t('qrGenerator.productsTable.data_available')"
              v-if="typeOfProfile == 'another'"
              :label="$t('QrSeries.step_one.other_profile')"
              :items="profiles"
              item-text="customId"
              item-value="id"
              v-model="profile"
              :rules="[rules.isRequired]"
            ></v-select>
          </v-col>
          <v-col>
            <h3>{{ $t("QrSeries.step_one.template_subtitle") }}</h3>
            <v-select
              :no-data-text="$t('qrGenerator.productsTable.data_available')"
              dense
              class="pa-5 ma-0"
              :label="$t('QrSeries.step_one.template_type')"
              :items="$t('QrSeries.step_one.templates_array')"
              v-model="templateType"
              :rules="[rules.isRequired]"
            ></v-select>
            <v-select
              :no-data-text="$t('qrGenerator.productsTable.data_available')"
              dense
              v-if="templateType == 'complete'"
              :label="$t('QrSeries.step_one.customer_invoice')"
              v-model="createComplete"
              :items="templatesItems"
              item-text="name"
              item-value="id"
              :rules="[rules.isRequired]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
      </v-row>
      <v-row>
        <v-col>
          <h3  v-if="templateType == 'complete'">{{$t('QrSeries.step_one.integration_title')}}</h3>
          <v-select
            :no-data-text="$t('qrGenerator.productsTable.data_available')"
             v-if="templateType == 'complete'"
            :label="$t('QrSeries.step_one.selection_integration')"
            :items="$t('QrSeries.step_one.selection_array')"
            v-model="SelectionIntegration"
            :rules="[rules.isRequired]"
          ></v-select>
        </v-col>
        <v-col>
          <h3  v-if="templateType == 'complete'"> {{$t("QrSeries.step_one.selectpayable_subtitle")}}</h3>
          <v-select
           :no-data-text="$t('qrGenerator.productsTable.data_available')"
           v-if="templateType == 'complete'"
            :label="$t('QrSeries.step_one.selection_field')"
            :items="$t('QrSeries.step_one.selection_payable_array')"
            v-model="payableSelected"
            :rules="[rules.isRequired]"
          >
          </v-select>         
          <v-menu
              v-if="payableSelected == 'DateF' && templateType == 'complete'"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="payable_until"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template  v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="payable_until"
                  :label="$t('QrSeries.date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.isRequired]"
              ></v-text-field>
            </template>
            <v-date-picker
            v-model="payable_until"
            :locale="$store.state.User.language"
            no-title
            scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >{{$t('QrSeries.step_one.btn_cancel')}}</v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(payable_until)"
              >{{$t("Globals.ok")}}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import rules from "@/mixins/rules";
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [rules],
  data() {
    return {
      menu: false,
      createComplete: "",
      payableSelected:'',
      SelectionIntegration: "",
      today: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      templatesItems: [],
    };
  },
  computed: {
    ...mapGetters({
      profiles: 'qrSeries/getProfiles'
    }),
    payable_until: {
      get() {
        return this.$store.state.QR.payable_until;
      },
      set(value) {
        this.$store.state.QR.payable_until = value;
      },
    },
    typeOfProfile: {
      get() {
        return this.$store.state.qrSeries.typeOfProfile
      },
      set(value) {
        this.$store.state.qrSeries.typeOfProfile = value;
      }
    },
    profile: {
      get() {
        return this.$store.state.qrSeries.profile;
      },
      set(value) {
        this.$store.commit('qrSeries/setProfile', value)
      }
    },
    templateType: {
      get() {
        return this.$store.state.templateEditor.templateType;
      },
      set(value) {
        this.$store.state.templateEditor.templateType = value;
      }
    },
  },
  watch: {
    SelectionIntegration(newValue, oldValue) {
      let renderQR = true
      if(newValue === 'separate') {
        renderQR = false;
      }

      this.$store.commit('templateEditor/setRenderQR', renderQR)
    },
    async createComplete(newValue, oldValue) {
      let foundTemplate =  await this.templatesItems.find(element => element.id === newValue);
      this.$store.state.qrSeries.selected_template_settings = foundTemplate ? JSON.parse(foundTemplate.json_settings || '') : null;
      this.$emit('update-template', newValue)
    }
  },
  methods: {
    ...mapMutations({
      setProfiles: 'qrSeries/setProfiles'
    })
  },
  async created() {
    await fetch(`${process.env.VUE_APP_SERVER_URL}/api/getTemplates`, {
      method: "GET",
      credentials: "include",
    })
    .then((result) => result.json())
    .then(async ({ templates }) => {
      if (templates) {
        this.templatesItems.push(
          ...templates.map((template) => ({
            name: template.template_name,
            id: template.id,
            json_settings:template.json_settings
          }))
        );
      }
    })
    .catch((err) => {
      this.enableLoader = false;
      return this.$store.commit("global/setSnackbar", { message: err });
    });

    await fetch(`${process.env.VUE_APP_SERVER_URL}/api/profiles`, {
      method: "GET",
      credentials: "include",
    })
    .then((result) => result.json())
    .then(async ({ profiles }) => {
      if(profiles) {
        this.setProfiles([...profiles.map(profile => {
            return {
              id:profile.id,
              accountNumber: profile.iban,
              accountNumberWir: profile.iban_wir,
              accountQRNumber: profile.qr_iban,
              accountQRNumberWir: profile.qr_iban_wir,
              UIDNumber: profile.uid_number,
              reference: profile.reference,
              firstName: profile.first_name,
              selectedCurrecy: profile.currency,
              lastName: profile.last_name,
              street: profile.street,
              houseNumber: profile.house_number,
              zipCode: profile.zip_code,
              city: profile.city,
              selectedCountry: profile.country,
              defaultProfile: profile.default,
              customId: profile.custom_id,
            }
          })
        ])
      }
    })
    .catch((err) => {
      this.enableLoader = false;
      return this.$store.commit("global/setSnackbar", { message: err });
    });
  },
  beforeDestroy() {
    Object.assign(this, {
      menu: false,
      templateType: "",
      createComplete: "",
      payableSelected:'',
      SelectionIntegration: "",
      today: new Date().toISOString().substr(0, 10),
      payableSelected: "",
      date: new Date().toISOString().substr(0, 10),
      templatesItems: [],
      profile: '',
      typeOfProfile: 'standard',
      payable_until: '',
    })
  }

};
</script>
<template>
    <div>
        <v-row>
            <v-col cols="9" class="pb-0">
                <h3>{{ $t("Debtors.main_title") }}</h3>
            </v-col>
            <v-col cols="3" class="pb-0">
                <v-btn dark class="mb-0" :color="$vuetify.theme.themes.light.blue.primary" @click="showProductDialog">
                    {{ $t("Debtors.btn_new_product") }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :no-data-text="$t('qrGenerator.productsTable.data_available')" :headers="headers"
                    :items="productItems" multi-sort dense>
                    <template v-slot:item.name="{ item }">
                        <v-icon small color="red" class="ms-0" v-if="item.name.length > 40">mdi-alert-outline</v-icon>
                        <span v-html="item.name"></span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon class="mb-2" small @click="editProductItem(item)">mdi-pencil</v-icon>
                        <v-icon class="mb-2" small @click="deleteProductItem(item)">mdi-trash-can</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field :label="$t('Debtors.totalAmount_title')" dense disabled
                    v-model="calculateTotalPrice"></v-text-field>
                <!-- <v-text-field :label="$t('Debtors.totalAmount_title_bexio')" dense disabled
                    v-model="total_amount_bexio"></v-text-field> -->
            </v-col>
        </v-row>
        <v-dialog persistent v-model="showProductDialogFlag" width="500px">
            <v-card width="500px">
                <v-card-title>{{ productDialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="productData">
                        <v-row>
                            <v-col>
                                <v-text-field :label="$t('Debtors.name')" maxlength="40" dense counter
                                    v-model="product_item.name"
                                    :rules="[rules.isMissing, rules.noInvalidCharacters, rules.invalidNameLength]"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field :label="$t('Debtors.vat_date_percentage')" suffix="%" dense
                                    v-model="product_item.vat" type="number"
                                    :rules="[rules.isMissingNumber, rules.isGreaterZero, rules.noInvalidCharacters]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :label="$t('Debtors.quantity_product')" dense
                                    v-model="product_item.quantity" type="number" @input="calculateAmounts"
                                    :rules="[rules.isMissingNumber, rules.isGreaterZero, rules.noInvalidCharacters]"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field :label="$t('Debtors.unit_price_product')" dense
                                    v-model="product_item.unitPrice" type="number" @input="calculateAmounts"
                                    :rules="[rules.isMissingNumber, rules.noInvalidCharacters]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row>
                        <v-col class="col-6" v-if="hasWir">
                            <v-text-field :label="$t('Debtors.share_wir')" dense
                                :rules="[rules.percentage, rules.noInvalidCharacters]"
                                v-model="product_item.percentage_wir" type="number" @input="calculateAmounts">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col>
                            <v-text-field :label="$t('Debtors.net_price_product')" dense v-model="calculateNetPrice"
                                disabled></v-text-field>
                        </v-col>

                        <v-col>
                            <v-text-field :label="$t('Debtors.gross_price')" dense v-model="calculateGrossPrice"
                                disabled></v-text-field>
                        </v-col>

                        <v-col v-if="hasWir">
                            <v-text-field :label="$t('Debtors.amount_CHF')" dense v-model="product_item.amount_CHF"
                                disabled></v-text-field>
                        </v-col>

                        <v-col v-if="hasWir">
                            <v-text-field :label="$t('Debtors.amount_CHW')" dense v-model="product_item.amount_CHW"
                                disabled></v-text-field>
                        </v-col>

                        <v-col>
                            <v-text-field :label="$t('Debtors.vat_product')" dense v-model="calculateVATProduct"
                                disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="col-6" v-if="hasWir">
                            <v-checkbox class="ma-0 pa-0" v-model="excludeVAT"
                                :label="$t('Debtors.excludeVAT')"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="col-6">
                            <v-select :label="$t('Debtors.VATmethod')" :items="$t('Debtors.VATmethodOptions')" dense
                                v-model="product_item.vatMethod" @input="calculateAmounts">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn :color="$vuetify.theme.themes.light.blue.primary" @click="hideProductDialog" dark>{{
                        $t("Debtors.btn_cancel") }}</v-btn>
                    <v-btn :color="$vuetify.theme.themes.light.blue.primary" @click="saveProductDialogChanges" dark>{{
                        $t("Debtors.btn_save") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { validateCharac } from "../../../../functions/invalidCharacters";

export default {
    name: 'debtor-table-productsTable',
    data() {
        return {
            // total_amount_bexio: 0,
            showProductDialogFlag: false,
            currentProductIndex: null,
            amount_CHF: 0,
            amount_CHW: 0,
            productItems: [],
            excludeVAT: false,
            billing_round_difference: null,
            product_item: {
                id: null,
                name: "",
                quantity: null,
                unitPrice: null,
                vat: null,
                netPriceProduct: this.calculateNetPrice,
                grossPriceProduct: this.calculateGrossPrice,
                vatProduct: this.calculateVATProduct,
                amount_CHF: 0,
                amount_CHW: 0,
                percentage_wir: 0,
                vatMethod: 'deduct'
            },
            default_product_item: {
                id: null,
                name: "",
                quantity: null,
                unitPrice: null,
                vat: null,
                netPriceProduct: null,
                grossPriceProduct: null,
                vatProduct: null,
                amount_CHF: 0,
                amount_CHW: 0,
                percentage_wir: 0
            },
            rules: {
                invalidNameLength: (v) => v.length <= 40 || this.$t('rules.product_name_length'),
                isMissing: (v) => !!v || this.$t('rules.required'),
                isMissingNumber: (v) => (v !== undefined && v !== null && v !== '') || this.$t('rules.required'),
                isGreaterZero: (v) => (v >= 0) || this.$t('rules.isLessZero'),
                percentage: value => value >= 0 && value <= 100 || this.$t('rules.percentage'),
                noInvalidCharacters: value => {
                    if (validateCharac(value)) {
                        return this.$t("Globals.error_character");
                    } else {
                        return true;
                    }
                }
            }
        }
    },
    created() {
        if (!this.productItems) {
            this.productItems = [];
        }
    },
    computed: {

        columns() {
            return [
                // { text: "", value: "error", width: 60 },
                { text: this.$t('Debtors.name'), value: "name" },
                { text: this.$t('Debtors.quantity_product'), value: "quantity" },
                { text: this.$t('Debtors.unit_price_product'), value: "unitPrice" },
                { text: this.$t('Debtors.vat_percentage'), value: "vat" },
                { text: this.$t('Debtors.net_price_product'), value: "netPriceProduct" },
                { text: this.$t('Debtors.vat_product'), value: "vatProduct" },
                { text: this.$t('Debtors.share_wir'), value: "percentage_wir" },
                { text: this.$t('Debtors.gross_price'), value: "grossPriceProduct" },
                { text: this.$t('Debtors.amount_CHF'), value: "amount_CHF" },
                { text: this.$t('Debtors.amount_CHW'), value: "amount_CHW" },
                { text: this.$t('Debtors.actions'), value: "actions", sortable: false },

                // { text: this.$t('Debtors.name'), value: "name" },
                // { text: this.$t('Debtors.quantity_product'), value: "quantity" },
                // { text: this.$t('Debtors.unit_price_product'), value: "unitPrice" },
                // { text: this.$t('Debtors.vat_percentage'), value: "vat" },
                // { text: this.$t('Debtors.gross_price'), value: "grossPriceProduct" },
                // { text: this.$t('Debtors.vat_product'), value: "vatProduct" },
                // { text: this.$t('Debtors.share_wir'),value:"percentage_wir"},
                // { text: this.$t('Debtors.net_price_product'), value: "netPriceProduct" },
                // { text: this.$t('Debtors.amount_CHF'), value: "amount_CHF" },
                // { text: this.$t('Debtors.amount_CHW'), value: "amount_CHW" },
                // { text: this.$t('Debtors.actions'), value: "actions", sortable: false },
            ]
        },

        headers() {
            if (this.hasWir) {

                return this.columns;

            } else {

                let ignoreColumns = ['amount_CHF', 'amount_CHW', 'percentage_wir'];
                return this.columns.filter(element => !ignoreColumns.includes(element.value));
            }
        },
        hasWir() {
            return this.$store.state.User.wir_active;
        },
        calculateTotalPrice() {
            let totalAmount = 0;
            this.productItems.forEach(item => {
                if (item.vatMethod === 'add') {
                    totalAmount += Number(item.grossPriceProduct)
                } else {
                    totalAmount += Number(item.quantity) * Number(item.unitPrice);

                }

            });

            totalAmount += Number(this.billing_round_difference)


            let total = Math.round(totalAmount * Math.pow(10, 2)) / Math.pow(10, 2)
            total = total.toString().split(".")
            return total.length > 1
                ? `${total[0]}.${total[1].padEnd(2, 0)}`
                : total[0] + ".00";
        },
        calculateVATProduct() {
            // let resultVATCalculation = (this.calculateGrossPrice - this.calculateNetPrice).toFixed(2);
            // this.product_item.vatProduct = resultVATCalculation
            // return resultVATCalculation;
            if (this.product_item.vat !== null && this.product_item.vat !== undefined && this.product_item.vat !== '') {
                if (this.product_item.unitPrice !== null && this.product_item.unitPrice !== undefined && this.product_item.unitPrice !== '') {
                    if (this.product_item.quantity !== null && this.product_item.quantity !== undefined && this.product_item.quantity !== '') {

                        let resultVATCalculation = (this.calculateGrossPrice - this.calculateNetPrice).toFixed(2);
                        this.product_item.vatProduct = resultVATCalculation
                        return resultVATCalculation;


                    }
                }
            }
            return 0;
        },
        calculateGrossPrice() {

            //     if(this.product_item.unitPrice !== null && this.product_item.unitPrice !== undefined && this.product_item.unitPrice !== ''){
            //             if(this.product_item.quantity !== null && this.product_item.quantity !== undefined && this.product_item.quantity !== ''){
            //             let resultGrossPrice = ( Number(this.product_item.quantity) * Number(this.product_item.unitPrice) ).toFixed(2);
            //             let percentage_wir =  Number(this.product_item.percentage_wir);

            //             this.product_item.grossPriceProduct = resultGrossPrice


            //                 if( percentage_wir > 0 && percentage_wir < 100 ){
            //                     this.calculateAmounts()
            //                 }

            //                 return resultGrossPrice;
            //             }
            //    }
            //     return 0;
            if (this.product_item.vat !== null && this.product_item.vat !== undefined && this.product_item.vat !== '') {
                if (this.product_item.unitPrice !== null && this.product_item.unitPrice !== undefined && this.product_item.unitPrice !== '') {
                    if (this.product_item.quantity !== null && this.product_item.quantity !== undefined && this.product_item.quantity !== '') {
                        let resultGrossPrice = 0;
                        if (this.product_item.vatMethod === 'add') {
                            resultGrossPrice = (Number(this.product_item.quantity) * Number(this.product_item.unitPrice) * (1 + (Number(this.product_item.vat) / 100))).toFixed(2);
                        } else {
                            resultGrossPrice = (Number(this.product_item.quantity) * Number(this.product_item.unitPrice)).toFixed(2);

                        }
                        let percentage_wir = Number(this.product_item.percentage_wir);
                        this.product_item.grossPriceProduct = resultGrossPrice;
                        if (percentage_wir > 0 && percentage_wir < 100) {
                            this.calculateAmounts()
                        }
                        return resultGrossPrice;
                    }
                }
            }
            return 0;
        },

        calculateNetPrice() {
            // if(this.product_item.vat !== null && this.product_item.vat !== undefined && this.product_item.vat !== ''){
            //     if(this.product_item.unitPrice !== null && this.product_item.unitPrice !== undefined && this.product_item.unitPrice !== ''){
            //         if(this.product_item.quantity !== null && this.product_item.quantity !== undefined && this.product_item.quantity !== ''){
            //             let resultNetPrice = ((Number(this.product_item.quantity) * Number(this.product_item.unitPrice))/(1 + (Number(this.product_item.vat)/100))).toFixed(2);
            //             this.product_item.netPriceProduct = resultNetPrice
            //             return resultNetPrice;
            //         }
            //     }
            // }
            if (this.product_item.vat !== null && this.product_item.vat !== undefined && this.product_item.vat !== '') {
                if (this.product_item.unitPrice !== null && this.product_item.unitPrice !== undefined && this.product_item.unitPrice !== '') {
                    if (this.product_item.quantity !== null && this.product_item.quantity !== undefined && this.product_item.quantity !== '') {
                        let resultNetPrice = 0;
                        if (this.product_item.vatMethod === 'add') {
                            resultNetPrice = (Number(this.product_item.quantity) * Number(this.product_item.unitPrice)).toFixed(2);
                        } else {
                            resultNetPrice = ((Number(this.product_item.quantity) * Number(this.product_item.unitPrice)) / (1 + (Number(this.product_item.vat) / 100))).toFixed(2);
                        }
                        this.product_item.netPriceProduct = resultNetPrice
                        return resultNetPrice;
                    }
                }
            }
            return 0;
        },
        productDialogTitle() {
            if (this.currentProductIndex != -1 && (this.currentProductIndex || this.currentProductIndex == 0)) {
                return this.$t("Debtors.editProduct");
            } else {
                return this.$t("Debtors.newProduct");
            }
        },
    },
    methods: {
        calculateAmounts() {
            // this.product_item.quantity = Math.trunc(this.product_item.quantity)
            let resultGrossPrice = (Number(this.product_item.quantity) * Number(this.product_item.unitPrice)).toFixed(2);
            let percentage_wir = Number(this.product_item.percentage_wir);

            let amount = 0;
            if (!this.excludeVAT) {
                amount = resultGrossPrice;
            } else {
                amount = this.calculateNetPrice
            }

            //format percentage
            percentage_wir = Math.floor(percentage_wir) + Math.floor(((percentage_wir - Math.floor(percentage_wir)) * 10)) / 10;


            let amount_CHW = ((percentage_wir / 100) * amount)

            let decimalCHW = Math.floor((amount_CHW - Math.floor(amount_CHW)) * 100);

            let centesimaCHW = decimalCHW % 10;

            let result = 0;

            //rounder the amountCHW
            if (centesimaCHW >= 1 && centesimaCHW < 5) {
                let decimals = Math.floor(decimalCHW / 10) / 10 + 0.05;
                result = Math.floor(amount_CHW) + Math.floor(decimals * 100) / 100;
                this.product_item.amount_CHW = this.formatNumber(String(result));
            } else if (centesimaCHW > 5 && centesimaCHW <= 9) {
                result = Math.floor(amount_CHW) + ((decimalCHW - centesimaCHW) + 10) / 100;
                this.product_item.amount_CHW = this.formatNumber(String(result));
            } else {
                result = (Math.floor(amount_CHW) + Math.floor((amount_CHW - Math.floor(amount_CHW)) * 100) / 100)
                this.product_item.amount_CHW = this.formatNumber(String(result));
            }

            //set the result to product
            this.product_item.amount_CHF = (amount - this.product_item.amount_CHW).toFixed(2);
            console.log('e', this.product_item)

        },

        showProductDialog() {
            this.currentProductIndex = null;
            Object.assign(this.product_item, this.default_product_item);
            return this.showProductDialogFlag = true;
        },
        hideProductDialog() {
            this.currentProductIndex = null;
            Object.assign(this.product_item, this.default_product_item);
            this.showProductDialogFlag = false;
        },
        updateArray(index, element) {

            //update the array with the new info of product
            this.productItems.splice(index, 1);
            let auxArray = [...this.productItems];
            //insert the new element in specific position
            auxArray.splice(index, 0, element);
            this.productItems = auxArray;


        },
        async saveProductDialogChanges() {
            if (!this.$refs.productData.validate() || (this.product_item.percentage_wir > 100 || this.product_item.percentage_wir < 0)) {
                return this.$store.commit(`global/setSnackbar`, { message: this.$t('snack_confirm.invalid_inputs') });
            }

            if (this.currentProductIndex !== undefined && this.currentProductIndex !== null) {
                //Object.assign(this.productItems[this.currentProductIndex],this.product_item);
                //await this.$set(this.productItems,this.currentProductIndex, this.product_item);
                const auxCopy = Object.assign({}, this.product_item);
                this.updateArray(this.currentProductIndex, auxCopy);
            } else {
                let calculatedId = 1;
                if (this.productItems.length > 1) {
                    let getHighestId = await this.productItems.sort((a, b) => b.id - a.id);
                    calculatedId = getHighestId[0].id + 1;
                } else if (this.productItems.length == 1) {
                    let getFistValue = this.productItems[0];
                    calculatedId = getFistValue.id + 1;
                }
                await this.productItems.push({
                    id: calculatedId,
                    name: this.product_item.name,
                    quantity: this.product_item.quantity,
                    unitPrice: this.product_item.unitPrice,
                    vat: this.product_item.vat,
                    netPriceProduct: this.product_item.netPriceProduct,
                    grossPriceProduct: this.product_item.grossPriceProduct,
                    vatProduct: this.product_item.vatProduct,
                    amount_CHF: this.product_item.amount_CHF,
                    amount_CHW: this.product_item.amount_CHW,
                    percentage_wir: this.product_item.percentage_wir,
                    vatMethod: this.product_item.vatMethod
                });
            }

            //clean de object product_item
            Object.assign(this.product_item, this.default_product_item);


            this.currentProductIndex = null;
            this.showProductDialogFlag = false;

        },
        editProductItem(item) {
            console.log(item);
            this.currentProductIndex = this.productItems.indexOf(item);
            Object.assign(this.product_item, item);
            if (!item.vatMethod) {
                this.product_item.vatMethod = 'deduct';
            }
            return this.showProductDialogFlag = true;
        },
        deleteProductItem(item) {
            this.$root.$confirm(this.$t('Globals.confirm'), this.$t('Debtors.delete_product'), { color: 'red' })
                .then((confirmation) => {
                    if (confirmation) {
                        let currentPItemIndex = this.productItems.indexOf(item);
                        this.productItems.splice(currentPItemIndex, 1);
                    }
                });
        },
        formatNumber(number) {
            let formatNumber = number.split(".");
            return formatNumber.length > 1 ? `${formatNumber[0]}.${formatNumber[1].padEnd(2, 0)}` : formatNumber[0] + ".00";
        },
    }
}
</script>
<template>
  <div>
    <v-form ref="form">
      <v-row> 
        <v-col class="pa-0 ma-0" cols="7">
          <v-card class="pa-0 ma-6">
            <v-card-title> {{ $t("Settings.title") }} </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="6" class="pt-1 pl-8 pb-0">{{
                  $t("Settings.User_Details.user_subtitle")
                  }}</v-col>
                <v-col cols="6" class="pt-1 pb-0">{{
                  $t("Settings.BillingAddress.billing_subtitle")
                  }}</v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text class="pb-0">
              <v-row>
                <v-col class="pl-8">
                  <v-text-field v-model="userEmail" :rules="emailRules" :label="$t('Settings.User_Details.user_name')"
                    :loading="loadingState" required outlined dense :disabled="vselect"></v-text-field>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" :items="$t('StepByStep.UserInformation.legalform_entry')"
                    :loading="loadingState" :label="$t('Settings.User_Details.legal_form')" v-model="legalFormSelected"
                    @change="legalFormFunction" :rules="[(v) => !!v || $t('rules.notempty')]" outlined dense></v-select>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" v-if="industryStatus1" :loading="loadingState"
                    :items="$t('StepByStep.UserInformation.industryItems1')"
                    :label="$t('Settings.User_Details.industry')" v-model="industrySelected" @change="industryFunction"
                    outlined dense></v-select>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" v-if="industryStatus2" :loading="loadingState"
                    :items="$t('StepByStep.UserInformation.industryItems2')"
                    :label="$t('Settings.User_Details.industry')" v-model="industrySelected" @change="industryFunction"
                    outlined dense></v-select>

                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" v-if="numberEmployeesStatus" :loading="loadingState"
                    :items="numberMembersItems" :label="$t('Settings.User_Details.number_employees')"
                    v-model="numberEmployeesSelected" outlined dense></v-select>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" v-if="numberMembersStatus" :loading="loadingState"
                    :items="numberMembersItems" :label="$t('Settings.User_Details.number_members')"
                    v-model="numberMembersSelected" outlined dense></v-select>

                  <v-text-field :disabled="vselect" v-if="UIDStatus" v-model="UIDText" :rules="[rules.UIDRules]"
                    :label="$t('Settings.User_Details.mwstnr')" :loading="loadingState" prefix="CHE-"
                    v-mask="'###.###.###'" required outlined dense>
                  </v-text-field>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" class="pt-5" dense :items="$t('Settings.languageSelector.languages')" :label="$t('Settings.languageSelector.languageSelector_field')
                      " v-model="languageSelector" outlined></v-select>
                  <v-checkbox label="bexio" color="success" v-model="processBexio" hide-details
                    @click="bexioProccess" v-show="!BankWir_Bxio_Partners_Validation"></v-checkbox> 
                </v-col>
                <v-col class="pt-3 pl-10 pr-8">
                  <v-text-field :disabled="vselect" v-if="companyStatus" :loading="loadingState"
                    :label="$t('Settings.BillingAddress.company')" v-model="companyText" :rules="[rules.companyRules]"
                    outlined dense>
                  </v-text-field>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" v-if="salutationStatus" :loading="loadingState"
                    :label="$t('Settings.BillingAddress.salutation')" v-model="salutationText"
                    :rules="[rules.salutationRules]" :items="$t('salutationList')" outlined dense>
                  </v-select>
                  <!-- new fields -->
                  <v-text-field :disabled="vselect" v-if="contactStatus"
                    :label="$t('Settings.BillingAddress.contact_name')" v-model="userName"
                    :rules="[rules.contactNameRules]" required outlined dense></v-text-field>
                  <v-text-field :disabled="vselect" v-if="contactStatus"
                    :label="$t('Settings.BillingAddress.conttact_Lname')" v-model="userLName"
                    :rules="[rules.contactLNameRules]" required outlined dense></v-text-field>
                  <!-- *************** -->
                  <v-text-field :disabled="vselect" v-if="nameStatus" v-model="userName"
                    :rules="[rules.userNameRules, rules.noInvalidCharacters]"
                    :label="$t('Settings.BillingAddress.name')" :loading="loadingState" required outlined
                    dense></v-text-field>
                  <v-text-field :disabled="vselect" v-if="nameStatus" v-model="userLName"
                    :rules="[rules.noInvalidCharacters]" :label="$t('Settings.BillingAddress.lastname')"
                    :loading="loadingState" required outlined dense></v-text-field>
                  <v-text-field :disabled="vselect" v-model="addressSuffixText" :rules="[rules.noInvalidCharacters]"
                    :loading="loadingState" :label="$t('Settings.BillingAddress.address')" outlined
                    dense></v-text-field>
                  <v-row>
                    <v-col>
                      <v-text-field :disabled="vselect" v-model="userStreet"
                        :rules="[userStreetRules, rules.noInvalidCharacters]" :loading="loadingState"
                        :label="$t('Settings.BillingAddress.street')" outlined dense></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field :disabled="vselect" v-model="userHouseNumber" :rules="[
                        userHouseNumberRules,
                        rules.noInvalidCharacters,
                      ]" :loading="loadingState" :label="$t('Settings.BillingAddress.house_number')" outlined
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field :disabled="vselect" v-model="userZIP"
                        :rules="[rules.ZipRules, rules.noInvalidCharacters]" :loading="loadingState"
                        :label="$t('Settings.BillingAddress.zip')" outlined dense></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field :disabled="vselect" v-model="userCity"
                        :rules="[userCityRules, rules.noInvalidCharacters]" :loading="loadingState"
                        :label="$t('Settings.BillingAddress.city')" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" :items="this.$t('countryList')" item-text="name" item-value="code"
                    :label="$t('Settings.BillingAddress.country')" v-model="userCountry" :loading="loadingState"
                    outlined dense></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn class="ma-1" :color="$vuetify.theme.themes.light.blue.primary" dark
                    @click="change_password.show = true">{{ $t("Settings.Buttons.btn_change") }}</v-btn>
                  <v-btn class="ma-1" :color="$vuetify.theme.themes.light.blue.primary" dark
                    @click="(vselect = false), (show = true)">{{ $t("Settings.Buttons.btn_mutate") }}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn class="ma-1" dark :color="$vuetify.theme.themes.dark.brown.secondary" v-show="show"
                    @click="cancelMutation()">{{ $t("Settings.Buttons.btn_cancel") }}</v-btn>
                  <v-btn class="ma-1" :color="$vuetify.theme.themes.dark.green.secondary" min-width="80" max-width="80"
                    @click="validateuserData()" :loading="DOMElements.validateuserData.loading" dark v-show="show">{{
                      $t("Settings.Buttons.btn_save") }}</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showButtonAPI">
                <v-col>
                  {{ $t("Settings.api_message") }}
                </v-col>
                <v-col cols="2" class="pl-0 ml-0">
                  <v-btn dark :color="$vuetify.theme.themes.light.blue.primary" @click="openAPIUserDialog()">API
                    USERS</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pl-6">
          <v-card flat class="pa-1 ma-2">
            <v-card-title>{{
              $t("Settings.Payment_Method.title")
              }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect || (disableFieldPayment && userhasWir)" :loading="loadingState"
                    :items="$t('paymenMethod')" :label="$t('Settings.Payment_Method.payment_means')"
                    v-model="paymentSelected" :rules="[(v) => !!v || $t('rules.payment_rule')]"></v-select>
                </v-col>

                <v-col v-if="paymentSelected == 'Card Method'">
                  <v-select :no-data-text="$t('qrGenerator.productsTable.data_available')
                    " :disabled="vselect" :items="['Mastercard - VISA', 'PostFinance']" v-model="cardPaymentType"
                    :rules="[(v) => !!v || $t('rules.notempty')]"></v-select>
                </v-col>
              </v-row>

              <v-row v-if="userhasWir">
                <!-- show the types of monthly fees  -->
                <v-col>
                  <v-select :disabled="vselect" :items="$t('StepByStep.OrderConfirmation.monthlyFees')"
                    v-model="monthlyFeesType" :rules="[(v) => !!v || this.$t('rules.notempty')]"></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark :disabled="vselect"
                    :loading="DOMElements.NewMeanP.loading" @click="NewMeanP()">
                    {{ $t("Settings.Buttons.btn_enter") }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn v-if="paymentSelected == 'Card Method'" :disabled="vselect"
                    :color="$vuetify.theme.themes.light.blue.primary" dark @click="CreditCardInfo()"
                    :loading="DOMElements.CreditCardInfo.loading">{{ $t("Settings.Buttons.btn_show") }}</v-btn>
                </v-col>
              </v-row>
              <v-dialog :color="$vuetify.theme.themes.light.blue.primary" v-model="dialogshow"
                transition="dialog-bottom-transition" max-width="400">
                <v-card>
                  <v-toolbar color="primary" dark>{{
                    $t("Settings.CardInformation.card_information")
                    }}</v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline">
                            {{ $t("Settings.CardInformation.name_on_card") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <font size="5">
                              {{ this.CardName }}</font>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline">
                            {{ $t("Settings.CardInformation.card_number") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <font size="5"> {{ this.CardNum }}</font>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline">
                            {{ $t("Settings.CardInformation.brand_name") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <font size="5"> {{ this.CardBrand }}</font>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialogshow = false">{{
                      $t("Settings.Buttons.btn_close")
                      }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
          <v-dialog v-model="change_password.show" max-width="500px">
            <v-card>
              <v-card-title>
                {{ $t("Settings.Change_Password.title") }}
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="change_password">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field :label="$t('Settings.Change_Password.current_password')
                          " type="password" v-model="change_password.old_password"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :label="$t('Settings.Change_Password.new_password')" type="password"
                          v-model="change_password.new_password" :rules="[rules.passwordLength]"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :label="$t('Settings.Change_Password.confirm_password')
                          " type="password" v-model="change_password.new_password2"
                          :error-messages="passwordError()"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn :color="$vuetify.theme.themes.dark.brown.primary" @click="btnCancelPassword()" dark>{{
                  $t("Settings.Buttons.btn_cancel") }}</v-btn>
                <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark @click="btnSaveNewPassword"
                  :loading="DOMElements.btnSaveNewPassword.loading">{{ $t("Settings.Buttons.btn_save_new") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <v-dialog v-model="dialogBexio.activete.show" hide-overlay transition="dialog-bottom-transition" max-width="600"
        max-height="400">
        <v-card>
          <v-card-title>
            {{ $t("Settings.Alert.Bexios.active.title") }}
          </v-card-title>
          <v-card-text class="containerAll">
            <v-row>
              <v-col>
                <h3 v-if="dialogBexio.activete.status === 200">
                  {{ $t("Settings.Alert.Bexios.active.msgSucess") }}
                </h3>
                <h3 v-else-if="dialogBexio.activete.status === 403">
                  {{ $t("Settings.Alert.Bexios.active.msgBexioNotPartner") }}
                </h3>
                <h3 v-else>{{ $t("Settings.Alert.Bexios.active.msgFail") }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark @click="hiddeDialogResponse('activete')">{{
              $t("Settings.Alert.Bexios.active.btn_ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogBexio.deactivate.show" hide-overlay transition="dialog-bottom-transition" max-width="600"
        max-height="400">
        <v-card>
          <v-card-title>
            {{ $t("Settings.Alert.Bexios.deactivate.title") }}
          </v-card-title>
          <v-card-text class="containerAll">
            <v-row>
              <v-col>
                <h3 v-if="dialogBexio.deactivate.status === 404">
                  {{ $t("Settings.Alert.Bexios.deactivate.msgFail") }}
                </h3>
                <h3 v-else>
                  {{ $t("Settings.Alert.Bexios.deactivate.msgAlert") }}
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn :color="$vuetify.theme.themes.dark.brown.primary" dark @click="
              hiddeDialogResponse('deactivate') +
              (processBexio = !processBexio)
              ">{{ $t("Settings.Alert.Bexios.deactivate.btn_cancel") }}
            </v-btn>
            <v-btn :color="$vuetify.theme.themes.light.blue.primary" dark @click="
              dialogBexio.deactivate.status === 404
                ? hiddeDialogResponse('deactivate')
                : removeBexiosAuth()
              ">{{ $t("Settings.Alert.Bexios.deactivate.btn_ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog persistent v-model="showCardDialog" width="530px">
        <v-card>
          <v-card-title>{{ $t("Settings.card_registration") }}</v-card-title>
          <v-card-text class="pl-4">
            <iframe v-if="!showCardDialogErrorMessage" :src="cardURL" style="border-style: none" height="500px"
              width="500px"></iframe>
            <div v-if="showCardDialogErrorMessage">
              <p class="text-center">
                {{ $t("Settings.error_card") }}<br />Error code:{{ errorCode }}
              </p>
              <v-btn @click="showCardDialog = false" dark :color="$vuetify.theme.themes.light.blue.primary">{{
                $t("Settings.Buttons.btn_close") }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog persistent width="700px" v-model="showAPIDialog">
        <v-card>
          <v-card-title>API Users</v-card-title>
          <v-card-text>
            <v-data-table :items="api_users_items" :headers="headers.api_users_headers" hide-default-footer
              :loading="loadingAPIUsersT">
              <template v-slot:item.actions="{ item }">
                <v-icon :disabled="disableDeleteIcon" @click="deleteAPIUserReq(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn :disabled="createAPIUserBtn.disabled" :dark="createAPIUserBtn.dark"
              :color="$vuetify.theme.themes.light.blue.primary" @click="createAPIUserReq()"
              :loading="createAPIULoader">{{
                $t("Settings.Buttons.create_api") }}</v-btn>

            <v-btn :disabled="closeAPIUserBtn.disabled" :dark="closeAPIUserBtn.dark"
              :color="$vuetify.theme.themes.light.blue.primary" @click="showAPIDialog = false">{{
                $t("Settings.Buttons.btn_close")
              }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog persistent width="500px" v-model="showAPICredDialog">
        <v-card width="500px">
          <v-card-title>Credentials</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span>{{ $t("Settings.clientID") }}:
                  <span style="font-weight: bold; color: black">{{
                    currentClientId
                    }}</span><br />{{ $t("Settings.client_secret") }}:
                  <span style="font-weight: bold; color: black">{{
                    currentClientSecret
                    }}</span>
                  <v-divider></v-divider>
                  <br /><br /><v-icon class="ma-12" color="yellow darken-3">mdi-alert</v-icon><span
                    style="font-weight: bold; font-size: 30px; color: black">WARNING</span><v-icon
                    color="yellow darken-3" class="ma-12">mdi-alert</v-icon>
                  <br />{{ $t("Settings.text") }}<br />
                  {{ $t("Settings.text1") }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn dark :color="$vuetify.theme.themes.light.blue.primary" @click="closeAPICred()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import countryList from "../../../../countrylist.json";
import { mask } from "vue-the-mask";
import { isValidIBANNumber } from "@/mixins/functions";
import { validateCharac } from "../../../../functions/invalidCharacters";

export default {
  directives: {
    mask,
  },
  created() {
    //This commit  changes the title on the dashboard
    this.$store.commit("global/setPageTitle", this.$t("Settings.main_title"));
    //When the component is loaded runs this function
    //This function makes a request to the server through the "/api/creditordata"
    //to get the users data
    this.initialize();
  },
  computed: {
    locale() {
      return this.$store.state.User.language;
    },

    userhasWir() {
      return this.$store.state.User.wir_active;
    },

    showButtonAPI() {
      return (
        this.$store.getters["User/subscriptionName"] == "Angebot QR-Profi" ||
        this.$store.getters["User/subscriptionName"] == "Angebot QR-Plus" ||
        this.$store.getters["User/subscriptionName"] == "Angebot QR-Start"
      );
    },

    headers() {
      return {
        api_users_headers: [
          { text: "ID", value: "id" },
          { text: this.$t("Settings.clientID"), value: "client_id" },
          {
            text: this.$t("Settings.actions"),
            value: "actions",
            sortable: false,
          },
        ],
      };
    },
  },
  watch: {
    locale(newValue, oldValue) {
      this.$store.commit("global/setPageTitle", this.$t("Settings.main_title"));
    },
    monthlyFeesType(newValue, oldValue) {
      if (newValue == "50% of payment in CHW and 50% in CHF") {
        this.paymentSelected = "QR Bill";
        this.disableFieldPayment = true;
      } else {
        this.disableFieldPayment = false;
      }
    },
  },
  data() {
    return {
      currentClientId: "",
      currentClientSecret: "",
      monthlyFeesType: "Entire payment in CHF",
      disableFieldPayment: false,
      showAPICredDialog: false,
      createAPIUserBtn: {
        dark: true,
        disabled: false,
      },
      closeAPIUserBtn: {
        dark: true,
        disabled: false,
      },
      idCreditor: null,
      disableDeleteIcon: false,
      createAPIULoader: false,
      loadingAPIUsersT: false,
      api_users_items: [],
      showAPIDialog: false,
      cardPaymentType: "Mastercard - VISA",
      errorCode: "",
      showCardDialogErrorMessage: false,
      cardURL: "",
      showCardDialog: false,
      title: "Settings",
      vselect: true,
      vtext: true,
      show: false,
      alertPartnerMessage: "",
      alertPartnerShow: "",
      CardName: "",
      CardNum: "",
      CardBrand: "",
      Typeofcard: "",
      Typeofcard2: "",
      butonshow: "",
      dialogshow: false,
      change_password: {
        show: false,
        old_password: "",
        new_password: "",
        new_password2: "",
      },
      languageSelector: "",
      loadingState: true,
      email: "",
      password: "",
      MwStNr: "",
      tab: "tab-1",
      valid: true,
      validuser: true,
      currentSubscription: "",
      countryList: countryList,
      creditorQRIBAN: "",
      userName: "",
      userLName: "",
      userEmail: "",
      userStreet: "",
      userHouseNumber: "",
      userZIP: "",
      userCity: "",
      userCountry: "",
      userSuffix: "",
      userCompany: "",
      userIndustry: "",
      userLegalForm: "",
      userNumberMembers: "",
      userNumberEmployees: "",
      userPayment: "",
      userReference: "",
      userSalutation: "",
      legalFormOthersText: "",
      legalFormSelected: "",
      legalFormOthersStatus: false,
      industryItems: [],
      industryStatus1: false,
      industryStatus2: false,
      industrySelected: "",
      industryOthersText: "",
      industryOthersStatus: false,
      numberMembersItems: [
        "0-10",
        "11-50",
        "51-250",
        "251-1'000",
        "1'001-5'000",
        ">5'000",
      ],
      numberMembersSelected: "",
      numberMembersStatus: false,
      numberEmployeesSelected: "",
      numberEmployeesStatus: false,
      VatNoStatus: false,
      UIDText: "",
      UIDStatus: false,
      referenceText: "",
      salutationText: "",
      salutationStatus: true,
      contactStatus: false,
      companyStatus: false,
      companyText: "",
      paymentSelected: "",
      addressSuffixText: "",
      nameStatus: false,
      userHouseNumberRules: [
        (value) => {
          var RegEx = /^[a-z0-9]+$/i;
          if (value) {
            return RegEx.test(value) || this.$t("Settings.must_alphanumeric");
          }
          return true;
        },
      ],
      userCountryRules: [(v) => !!v || this.$t("Settings.entry_mandatory")],
      userCityRules: [(v) => !!v || this.$t("Settings.entry_mandatory")],
      userZipRules: [
        (v) => !!v || this.$t("Settings.entry_mandatory"),
        (v) => /^(\d{4})$/.test(v) || this.$t("Settings.must_contain"),
      ],
      userAddressRules: [(v) => !!v || this.$t("Settings.entry_mandatory")],
      userEmailRules: [
        (v) => !!v || this.$t("Settings.entry_mandatory"),
        (v) => /.+@.+\..+/.test(v) || this.$t("rules.email"),
      ],
      userStreetRules: [(v) => !!v || this.$t("Settings.entry_mandatory")],
      rules: {
        userNameRules: (v) => {
          return !!v || this.$t("rules.nameRules");
        },
        userLNameRules: (v) => {
          return !!v || this.$t("rules.lastNameRules");
        },
        contactNameRules: (v) => {
          return !!v || this.$t("rules.nameRules");
        },
        contactLNameRules: (v) => {
          return !!v || this.$t("rules.lastNameRules");
        },

        isIBAN: (value) => {
          const code = value ? value.replace(/\s+/g, "") : "";
          if (code.length > 0) {
            if (code[4] != 3) return true;
            else return this.$t("rules.isIBAN");
          } else {
            return true;
          }
        },

        isValidIBANNumber: (value) =>
          isValidIBANNumber(value) === 1
            ? true
            : this.$t("rules.isValidQRIBAN"),

        ibanCounter: (value) =>
          (value && value.length <= 26) ||
          this.$t("qrGeneratorFree.rules.ibanCounter"),

        notEmpty: (value) => !!value || this.$t("rules.notempty"),

        ZipRules: (userZIP) => {
          if (this.userZIP != 0) {
            return (
              this.userZIP.toString().length < 17 || this.$t("rules.zipLength")
            );
          } else {
            return this.$t("Settings.entry_mandatory");
          }
        },
        noInvalidCharacters: (value) => {
          if (validateCharac(value)) {
            return this.$t("Globals.error_character");
          } else {
            return true;
          }
        },
        UIDRules: (value) => {
          if (
            this.legalFormSelected === "Cooperative" ||
            this.legalFormSelected === "Limtied liability company"
          ) {
            return !!value || this.$t("Settings.entry_mandatory");
          } else {
            return true;
          }
        },
        salutationRules: (value) => {
          if (this.legalFormSelected === "Private person") {
            return !!value || this.$t("Settings.entry_mandatory");
          } else {
            return true;
          }
        },
        companyRules: (value) => {
          if (this.legalFormSelected !== "Private person") {
            return !!value || this.$t("Settings.entry_mandatory");
          } else {
            return true;
          }
        },
        passwordConfirmationRule() {
          return () =>
            this.change_password.new_password ===
            this.change_password.new_password2 ||
            this.$t("rules.passwordConfirmationRule");
        },
        passwordLength: (value) => {
          return String(value).length >= 7 || this.$t("rules.passwordLength");
        },
      },
      emailRules: [
        (v) => !!v || this.$t("rules.required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("rules.email"),
      ],
      creditor: {
        name: "",
        last_name: "",
        address: {
          street: "",
          number: "",
          zip: "",
          city: "",
          country: "",
        },
      },
      account: {
        name: "",
        last_name: "",
        address: {
          street: "",
          number: "",
          zip: "",
          city: "",
          country: "",
        },
      },
      DOMElements: {
        NewMeanP: {
          loading: false,
        },
        validateuserData: {
          loading: false,
        },
        btnSaveNewPassword: {
          loading: false,
        },
        CreditCardInfo: {
          loading: false,
        },
      },
      backupData: {
        creditor: {
          account: "",
          qr_account: "",
          language: "",
        },
        user: {
          name: "",
          last_name: "",
          email: "",
          street: "",
          number: "",
          zip: "",
          city: "",
          country: "",
          legal_form: "",
          address_suffix: "",
          legal_form_others: "",
          industry: "",
          industry_others: "",
          number_members: "",
          number_employees: "",
          UID: "",
          reference: "",
          salutation: "",
          company: "",
          payment: "",
          id_default_account: null,
        },
      },
      processBexio: null,
      dialogBexio: {
        activete: { show: false, status: null },
        deactivate: { show: false, status: null },
      },
      BankWir_Bxio_Partners_Validation: false
    };
  },
  mounted() {
    window.addEventListener("message", this.listenParentMessage, false);
    window.addEventListener("message", this.handleMessage, false);
  },
  destroyed() {
    window.removeEventListener("message", this.listenParentMessage, false);
    window.removeEventListener("message", this.handleMessage, false);
  },
  methods: {
    initialize() {
      //When the component is loaded runs this function
      //This function makes a request to the server through the "/api/creditordata"
      //to get the users data
      this.currentSubscription = this.$store.state.User.subscriptionName;
      this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/creditordata`)
        .then((response) => {
          console.log("data", response);
          if (response !== undefined || response !== null) {
            //If the response is 200, then the response field are mapped to its respective varaibles
            this.idCreditor = response.creditor.id;
            let data = {
              creditor: {
                account: response.creditor.account,
                qr_account: response.creditor.qr_account,
                language: response.creditor.language,
              },
              user: {
                name: response.user.name,
                last_name: response.user.last_name,
                email: response.user.email,
                street: response.user.street,
                number: response.user.number,
                zip: response.user.zip,
                city: response.user.city,
                country: response.user.country,
                legal_form: response.user.legal_form,
                address_suffix: response.user.address_suffix,
                legal_form_others: response.user.legal_form_others,
                industry: response.user.industry,
                industry_others: response.user.industry_others,
                number_members: response.user.number_members,
                number_employees: response.user.number_employees,
                UID: response.user.UID,
                reference: response.user.reference,
                salutation: response.user.salutation,
                company: response.user.company,
                payment: response.user.payment,
                id_default_account: response.creditor.id_default_account,
                monthlyFees: response.user.monthlyFees,
              },
              bexios: {
                superToken: response.bexios.status,
              },
              bexioValidationWir: response.bexioValidationWir
            };
            this.backupData = JSON.parse(JSON.stringify(data));
            this.setFieldValues(data);
          }
        })
        // When the response is done loading then the loading state is removed
        .then(() => (this.loadingState = false))
        .catch(this.$handleCatch);
    },
    bexioProccess() {
      this.processBexio
        ? this.openLoginBexio()
        : (this.dialogBexio.deactivate.show = true);
    },
    async openLoginBexio() {
      let bexioUser = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bexio/user`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      );
      console.log('bexioUser', bexioUser)
      if (bexioUser.status !== 200) {
        this.processBexio = !this.processBexio;
        this.dialogBexio.activete = { show: true, status: bexioUser.status };
        console.log('this.dialogBexio.activete', this.dialogBexio.activete)
        return;
      }

      console.log("Iniciando con el login bexio");
      let response = await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bexio/login`,
        {
          method: "GET",
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      );
      window.open(
        response.authUrl,
        "authWindow",
        "width=600,height=auto,resizable=no"
      );
    },
    hiddeDialogResponse(identify) {
      console.log(identify);
      this.dialogBexio[identify].show = false;
    },
    handleMessage(event) {
      console.log("Message received:", event.data.response);
      console.log("tye", typeof event.data.response);
      if (
        typeof event.data.response === "object" &&
        event.data.response.status === 200
      ) {
        console.log(event.data);
        event.data.response.message === "Connection successfully created"
          ? (this.dialogBexio.activete = { show: true, status: 200 })
          : (this.dialogBexio.activete = { show: true, status: 404 }) +
          (this.processBexio = !this.processBexio);
      }
    },
    async removeBexiosAuth() {
      console.log("removiendo token bexio");
      try {
        let removeConection = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/bexio/removeConection`,
          {
            method: "POST",
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );
        removeConection.status === 200
          ? this.hiddeDialogResponse("deactivate")
          : (this.dialogBexio.deactivate = { show: true, status: 404 }) +
          (this.processBexio = !this.processBexio);

        console.log(removeConection);
      } catch (err) {
        this.dialogBexio.deactivate = { show: true, status: 404 };
        this.processBexio = !this.processBexio;
        console.error(err);
      }
    },
    industryFunction(item) {
      //this function changes the status for the industry others field
      //when the users selects the others option in the industry field
      //then the field is shown
      let selected = this.industrySelected || item;
      if (this.industrySelected === "Others") {
        return (this.industryOthersStatus = true);
      } else {
        this.industryOthersText = "";
        return (this.industryOthersStatus = false);
      }
    },
    UIDclick() {
      //When the UID help icon is pressed this function is triggered
      //causing a new window to open with the following url
      window.open("https://www.zefix.ch/de/search/entity/welcome", "_blank");
    },
    legalFormFunction(item) {
      //This function show/hides certian fields depending the user's selection in the
      //legal form field. It also deletes the fields data if not needed.
      let selected = this.legalFormSelected || item;
      switch (selected) {
        case "Private person":
          this.industrySelected = "";
          this.industryOthersText = "";
          this.numberMembersSelected = "";
          this.numberEmployeesSelected = "";
          this.UIDText = "";
          this.companyText = "";
          this.companyStatus = false;
          this.contactStatus = false;
          this.nameStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = false;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = false;
          this.industryItems = this.industryItems2;
          this.industryStatus1 = false;
          this.industryStatus2 = false;
          this.VatNoStatus = false;
          break;
        case "Self-employed person(s)":
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.companyStatus = false;
          this.contactStatus = false;
          this.nameStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.industryItems = this.industryItems2;
          this.industryStatus1 = false;
          this.industryStatus2 = true;
          this.VatNoStatus = true;
          break;
        case "Sole proprietorship":
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.contactStatus = true;
          this.companyStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.numberEmployeesStatus = true;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.industryItems = this.industryItems2;
          this.industryStatus1 = false;
          this.industryStatus2 = true;
          this.VatNoStatus = true;
          break;
        case "Association":
          this.numberEmployeesSelected = "";
          this.UIDText = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.companyStatus = true;
          this.contactStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = false;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = true;
          this.numberEmployeesStatus = false;
          this.industryItems = this.industryItems1;
          this.industryStatus1 = true;
          this.industryStatus2 = false;
          this.VatNoStatus = false;
          break;
        case "Donation Organization":
          this.numberEmployeesSelected = "";
          this.UIDText = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.companyStatus = true;
          this.contactStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = false;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = true;
          this.numberEmployeesStatus = false;
          this.industryItems = this.industryItems1;
          this.industryStatus1 = true;
          this.industryStatus2 = false;
          this.VatNoStatus = false;
          break;
        case "Fundation":
          this.UIDText = "";
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.companyStatus = true;
          this.contactStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = false;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.industryItems = this.industryItems1;
          this.industryStatus1 = true;
          this.industryStatus2 = false;
          this.VatNoStatus = false;
          break;
        case "Cooperative":
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.companyStatus = true;
          this.contactStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.numberEmployeesStatus = true;
          this.industryItems = this.industryItems2;
          this.industryStatus1 = false;
          this.industryStatus2 = true;
          this.VatNoStatus = true;
          break;
        case "Limtied liability company":
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.nameStatus = false;
          this.companyStatus = true;
          this.contactStatus = true;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.legalFormOthersStatus = false;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.industryItems = this.industryItems2;
          this.industryStatus1 = false;
          this.industryStatus2 = true;
          this.VatNoStatus = true;
          break;
        case "Public administration":
          this.numberMembersSelected = "";
          this.industrySelected = "";
          this.industryOthersText = "";
          this.salutationText = "";
          this.companyStatus = true;
          this.contactStatus = true;
          this.nameStatus = false;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.legalFormOthersStatus = false;
          this.industryStatus1 = false;
          this.industryStatus2 = false;
          this.VatNoStatus = true;
          break;
        case "Others":
          this.numberMembersSelected = "";
          this.industrySelected = "";
          this.industryOthersText = "";
          this.salutationText = "";
          this.companyStatus = true;
          this.contactStatus = true;
          this.nameStatus = false;
          this.salutationStatus = true;
          this.UIDStatus = true;
          this.numberMembersStatus = false;
          this.numberEmployeesStatus = true;
          this.industryStatus1 = false;
          this.industryStatus2 = false;
          this.legalFormOthersStatus = true;
          this.VatNoStatus = true;
          break;

        default:
          this.industrySelected = "";
          this.industryOthersText = "";
          this.numberMembersSelected = "";
          this.salutationText = "";
          this.companyText = "";
          this.companyStatus = false;
          this.contactStatus = true;
          this.nameStatus = false;
          this.salutationStatus = true;
          this.industryItems = [];
          this.UIDStatus = false;
          this.numberEmployeesStatus = false;
          this.numberMembersStatus = false;
          this.industryStatus1 = false;
          this.industryStatus2 = false;
          this.legalFormOthersStatus = false;
          this.VatNoStatus = false;
          break;
      }
    },
    async NewMeanP() {
      this.showCardDialogErrorMessage = false;
      switch (this.paymentSelected) {
        case "Card Method":
          let postParam = this.cardPaymentType === "PostFinance" ? true : false;
          // let postParam = false;
          if (this.Typeofcard === "Card Method") {
            this.$root
              .$confirm(
                this.$t("Settings.confirm_title"),
                this.$t("Settings.message_card")
              )
              .then(async (response) => {
                if (response) {
                  this.DOMElements.NewMeanP.loading = true;
                  this.$fetch(
                    `${process.env.VUE_APP_SERVER_URL}/api/payment/card?lang=${this.$i18n.locale}&post=${postParam}`
                  )
                    .then((result) => {
                      this.DOMElements.NewMeanP.loading = false;
                      if (result) {
                        this.cardURL = result.redirectUrl;
                        this.showCardDialog = true;
                      }
                    })
                    .catch((err) => {
                      this.DOMElements.NewMeanP.loading = false;
                      return this.$handleCatch(err);
                    });
                }
              });
          } else {
            this.DOMElements.NewMeanP.loading = true;
            await this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/payment/card?lang=${this.$i18n.locale}&post=${postParam}`
            )
              .then((result) => {
                this.DOMElements.NewMeanP.loading = false;
                if (result) {
                  this.cardURL = result.redirectUrl;
                  this.showCardDialog = true;
                }
              })
              .catch((err) => {
                this.DOMElements.NewMeanP.loading = false;
                return this.$handleCatch(err);
              });
          }
          break;
        case "QR Bill":
          /*if (this.Typeofcard == "QR Bill") {
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("Settings.already_bills"),
            });
          } else { */
          this.$root
            .$confirm(
              this.$t("Settings.confirm_title"),
              this.$t("Settings.qrbill_method")
            )
            .then(async (response) => {
              if (response) {
                this.DOMElements.NewMeanP.loading = true;
                //Mostrar aqui el v dialog
                this.$fetch(
                  `${process.env.VUE_APP_SERVER_URL}/api/payment/method`,
                  {
                    method: "POST",
                    body: JSON.stringify({
                      lang: this.$store.getters["User/getLanguage"],
                      monthly_Fees: this.monthlyFeesType,
                    }),
                    headers: {
                      "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                    },
                  }
                ).then((response) => {
                  this.DOMElements.NewMeanP.loading = false;
                  this.Typeofcard = "QR Bill";
                  return this.$store.commit("global/setSnackbar", {
                    message: response.message,
                  });
                });
              } else {
                this.DOMElements.NewMeanP.loading = false;
                return this.$store.commit(`global/setSnackbar`, {
                  //explain this message
                  message: `Card is Still Your Payment Method`,
                });
              }
            });
          //}
          break;
      }
    },
    validateuserData() {
      if (!this.$refs.form.validate()) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("Settings.invalid_inputs"),
        });
      } else {
        this.DOMElements.validateuserData.loading = true;
        let JSONData = {
          username: this.userEmail,
          name: this.userName,
          lastName: this.userLName,
          email: this.userEmail,
          suffix: this.addressSuffixText,
          street: this.userStreet,
          houseNumber: this.userHouseNumber,
          zip: this.userZIP,
          city: this.userCity,
          country: this.userCountry,
          legalForm: this.legalFormSelected,
          legalFormOthers: this.legalFormOthersText,
          industry: this.industrySelected,
          industryOthers: this.industryOthersText,
          numberMembers: this.numberMembersSelected,
          numberEmployees: this.numberEmployeesSelected,
          UID: this.UIDText,
          salutation: this.salutationText,
          company: this.companyText,
          language: this.languageSelector,
        };
        let data = JSON.stringify(JSONData);
        //The function updates the user's data when all the validations are correct
        if (this.$refs.form.validate()) {
          fetch(
            `${process.env.VUE_APP_SERVER_URL}/api/updateUser?lang=${this.$store.getters["User/getLanguage"]}`,
            {
              method: "POST",
              credentials: "include",
              body: data,
              headers: {
                "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
              },
            }
          )
            .then((result) => {
              if (result.status == 401) {
                return this.$router.push({ name: "Login" });
              }
              return result.json();
            })
            .then((response) => {
              if (response !== undefined && response !== null) {
                this.$store.commit("User/setLogin", {
                  code: response.status,
                  user: {
                    UID: JSONData.UID,
                    address_suffix: JSONData.suffix,
                    city: JSONData.city,
                    company: JSONData.company,
                    country: JSONData.country,
                    email: JSONData.email,
                    industry: JSONData.industry,
                    industry_others: JSONData.industryOthers,
                    last_name: JSONData.lastName,
                    legal_form: JSONData.legalForm,
                    legalFormOthers: JSONData.legal_form_others,
                    name: JSONData.name,
                    number: JSONData.houseNumber,
                    number_employees: JSONData.numberEmployees,
                    number_members: JSONData.numberMembers,
                    salutation: JSONData.salutation,
                    street: JSONData.street,
                    username: JSONData.username,
                  },
                });

                this.initialize();
                this.vselect = true;
                this.show = false;
                this.$i18n.locale = this.languageSelector;
                this.$store.state.User.language = this.languageSelector;
                this.languageSelectorLabel = this.$t(
                  "app.languageSelector_field"
                );
                const { params, path } = this.$route;
                params.language = this.languageSelector;
                if (this.languageSelectorLabel === this.$i18n.locale) {
                  if (path === "/") {
                    this.$router.push({
                      params,
                      path: `/${this.languageSelector}`,
                    });
                  } else {
                    this.$router.push({ params });
                  }
                }
                return this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              }
            })
            .catch((err) => {
              return this.$store.commit("global/setSnackbar", { message: err });
            });
        }
        this.DOMElements.validateuserData.loading = false;
      }
    },
    async CreditCardInfo() {
      this.dialogshow = true;
      this.DOMElements.CreditCardInfo.loading = true;
      await fetch(`${process.env.VUE_APP_SERVER_URL}/api/payment/cards`, {
        method: "GET",
        credentials: "include",
      })
        .then((result) => {
          if (result.status == 401) {
            return this.$router.push({ name: "Login" });
          }
          return result.json();
        })
        .then((response) => {
          this.CardName = response.cards[0].holder_name;
          this.CardNum = response.cards[0].number;
          this.CardBrand = response.cards[0].brand_name;
        });
      this.DOMElements.CreditCardInfo.loading = false;
    },
    cancelMutation() {
      this.$root
        .$confirm(
          this.$t("Settings.confirm_title"),
          this.$t("Settings.cancel_mutation")
        )
        .then(async (response) => {
          if (response) {
            this.setFieldValues(this.backupData);
            this.vselect = true;
            this.show = false;
          } else
            return this.$store.commit(`global/setSnackbar`, {
              message: this.$t("Settings.unable_cancel"),
            });
        });
    },
    btnCancelPassword() {
      this.change_password.show = false;
      this.change_password.old_password = "";
      this.change_password.new_password = "";
      this.change_password.new_password2 = "";
    },
    async btnSaveNewPassword() {
      // Check for errors
      if (
        this.change_password.old_password == "" ||
        this.change_password.new_password == "" ||
        this.change_password.new_password2 == ""
      ) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("Settings.fields_missing"),
        });
      }
      if (
        this.change_password.new_password != this.change_password.new_password2
      ) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("Settings.new_passoword"),
        });
      }
      if (this.change_password.new_password.length <= 5) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("Settings.password_digits"),
        });
      }
      // LLama al endpoint para verificar que la contraseña sea correcta, y en dado caso, reemplazarla
      this.DOMElements.btnSaveNewPassword.loading = true;
      await fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/changePassword?lang=${this.$store.getters["User/getLanguage"]}`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            old_password: this.change_password.old_password,
            new_password: this.change_password.new_password,
          }),
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      )
        .then((result) => {
          if (result.status == 401) {
            return this.$router.push({ name: "Login" });
          }
          return result.json();
        })
        .then((result) => {
          this.change_password.show = false;
          this.change_password.old_password = "";
          this.change_password.new_password = "";
          this.change_password.new_password2 = "";
          this.$router.push({ name: "Login" });
          return this.$store.commit("global/setSnackbar", {
            message: result.message,
          });
        })
        .catch((err) => {
          this.$store.commit("global/setSnackbar", { message: err });
        });
      this.DOMElements.btnSaveNewPassword.loading = false;
    },

    passwordError() {
      return this.change_password.new_password ===
        this.change_password.new_password2
        ? ""
        : this.$t("rules.passwordConfirmationRule");
    },
    listenParentMessage(event) {
      if (event.origin !== `${process.env.VUE_APP_FRONT_URL}`) {
        return;
      } else {
        if (this.showCardDialog) {
          if (event.data) {
            if (typeof event.data === "object") {
              if (event.data.message) {
                this.cardURL = ``;
                if (event.data.message !== "Success") {
                  if (event.data.errorCode === "cancelled") {
                    return (this.showCardDialog = false);
                  }
                  this.errorCode = event.data.errorCode
                    ? event.data.errorCode
                    : "";
                  this.showCardDialogErrorMessage = true;
                } else {
                  this.showCardDialog = false;
                  return this.$store.commit(`global/setSnackbar`, {
                    message: event.data.message,
                  });
                }
              } else {
                return;
              }
            } else {
              return;
            }
          }
        } else {
          return;
        }
      }
    },
    setFieldValues(data) {
      this.creditorIBAN = data.creditor.account;
      this.creditorQRIBAN = data.creditor.qr_account;
      this.userName = data.user.name;
      this.userLName = data.user.last_name;
      this.userEmail = data.user.email;
      this.userStreet = data.user.street;
      this.userHouseNumber = data.user.number;
      this.userZIP = data.user.zip;
      this.userCity = data.user.city;
      this.userCountry = data.user.country;
      this.legalFormFunction(data.user.legal_form);
      this.addressSuffixText = data.user.address_suffix || "";
      this.legalFormSelected = data.user.legal_form || "";
      this.legalFormOthersText = data.user.legal_form_others || "";
      this.industrySelected = data.user.industry || "";
      this.industryOthersText = data.user.industry_others || "";
      this.industryFunction(data.user.industry);
      this.numberMembersSelected = data.user.number_members || "";
      this.numberEmployeesSelected = data.user.number_employees || "";
      this.UIDText = data.user.UID || "";
      this.referenceText = data.user.reference || "";
      this.languageSelector = data.creditor.language;
      this.salutationText = data.user.salutation || "";
      this.companyText = data.user.company || "";
      this.paymentSelected = data.user.payment || "";
      this.monthlyFeesType = data.user.monthlyFees;
      this.defaultAccount = data.creditor.id_default_account || "";
      this.selectedAccount = data.creditor.id_default_account || "";
      this.Typeofcard2 = data.user.payment;
      this.processBexio = data.bexios.superToken;
      this.BankWir_Bxio_Partners_Validation = data.bexioValidationWir;
    },
    async openAPIUserDialog() {
      this.createAPIUserBtn.dark = false;
      this.createAPIUserBtn.disabled = true;
      this.showAPIDialog = true;
      this.api_users_items = [];
      this.loadingAPIUsersT = true;
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/api_user`
        );
        if (response) {
          if (response.status == 200) {
            for (let i = 0; i < response.data.length; i++) {
              let currentItem = response.data[i];
              this.api_users_items.push({
                id: i + 1,
                client_id: currentItem.client_id,
              });
            }
          }
        }
        this.loadingAPIUsersT = false;
        this.createAPIUserBtn.dark = true;
        this.createAPIUserBtn.disabled = false;
      } catch (err) {
        this.createAPIUserBtn.dark = true;
        this.createAPIUserBtn.disabled = false;
        this.loadingAPIUsersT = false;
        return this.$store.commit(`global/setSnackbar`, {
          message: err.response.message || "",
        });
      }
    },
    async createAPIUserReq() {
      this.createAPIULoader = true;
      this.closeAPIUserBtn.dark = false;
      this.closeAPIUserBtn.disabled = true;
      this.disableDeleteIcon = true;
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/api_user`,
          {
            method: "POST",
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        );
        if (response) {
          if (response.status == 200) {
            this.api_users_items.push({
              id: 1,
              client_id: response.data.client_id,
              creation_date: response.data.creation_date,
            });
            this.currentClientId = `${response.data.client_id}`;
            this.currentClientSecret = `${response.data.client_secret}`;
            this.showAPICredDialog = true;
          }
          this.createAPIULoader = false;
          this.closeAPIUserBtn.dark = true;
          this.closeAPIUserBtn.disabled = false;
          this.disableDeleteIcon = false;
          return this.$store.commit(`global/setSnackbar`, {
            message: response.message || "",
          });
        } else {
          this.closeAPIUserBtn.dark = true;
          this.closeAPIUserBtn.disabled = false;
          this.createAPIULoader = false;
          this.disableDeleteIcon = false;
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("Globals.unable_server"),
          });
        }
      } catch (err) {
        this.createAPIULoader = false;
        this.closeAPIUserBtn.dark = true;
        this.closeAPIUserBtn.disabled = false;
        this.disableDeleteIcon = false;
        return this.$store.commit(`global/setSnackbar`, {
          message: err.response.message || "",
        });
      }
    },
    deleteAPIUserReq(item) {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to delete you API User?`,
          { color: "red" }
        )
        .then(async (responseFlag) => {
          if (responseFlag) {
            this.disableDeleteIcon = true;
            this.loadingAPIUsersT = true;
            this.createAPIUserBtn.dark = false;
            this.createAPIUserBtn.disabled = true;
            this.closeAPIUserBtn.dark = false;
            this.closeAPIUserBtn.disabled = true;
            try {
              let response = await this.$fetch(
                `${process.env.VUE_APP_SERVER_URL}/api/api_user`,
                {
                  method: "DELETE",
                  body: JSON.stringify({
                    client_id: item.client_id,
                  }),
                  headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                  },
                }
              );
              if (response) {
                if (response.status == 200) {
                  this.api_users_items.splice(item, 1);
                }
                this.createAPIUserBtn.dark = true;
                this.createAPIUserBtn.disabled = false;
                this.closeAPIUserBtn.dark = true;
                this.closeAPIUserBtn.disabled = false;
                this.loadingAPIUsersT = false;
                this.disableDeleteIcon = false;
                return this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              } else {
                this.createAPIUserBtn.dark = true;
                this.createAPIUserBtn.disabled = false;
                this.closeAPIUserBtn.dark = true;
                this.closeAPIUserBtn.disabled = false;
                this.loadingAPIUsersT = false;
                this.disableDeleteIcon = false;
                return this.$store.commit("global/setSnackbar", {
                  message: this.$t("Globals.unable_server"),
                });
              }
            } catch (err) {
              this.createAPIUserBtn.dark = true;
              this.createAPIUserBtn.disabled = false;
              this.closeAPIUserBtn.dark = true;
              this.closeAPIUserBtn.disabled = false;
              this.loadingAPIUsersT = false;
              this.disableDeleteIcon = false;
              return this.$store.commit("global/setSnackbar", {
                message: err.response.message,
              });
            }
          }
        });
    },
    closeAPICred() {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to proceed?`,
          { color: "blue" }
        )
        .then((response) => {
          if (response) {
            this.showAPICredDialog = false;
            this.currentClientId = ``;
            this.currentClientSecret = ``;
          }
        });
    },
  },
};
</script>

<style scoped></style>
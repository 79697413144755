<template>
    <v-container>
        <v-row>
            <v-col sm="6" lg="6" cols="12" >
                <v-card height="100%" >
                    <v-card-title>{{ $t("qrGenerator.payableTo.main_title") }}</v-card-title>
                    <v-card-text>
                        <v-form ref="form.creditor" >
                            <qr-form-creditor ref="qrFormCreditor"  />
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col sm="6" lg="6" cols="12" >
                <v-card height="100%" >
                    <v-card-title>{{ $t("qrGenerator.payableBy.main_title") }}</v-card-title>
                    <qr-form-debtor ref="qrFormDebtor" />
                </v-card>
            </v-col>
            <v-col cols="12" >
                <qr-form-billing ref="qrBilling" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" >
                <qr-card :withWir="false"/>
            </v-col>
        </v-row>
        <v-row>
            <qr-buttons v-bind:propsMap="{references:$refs}" />
        </v-row>
    </v-container>
</template>

<script>
import qrActionButtonsVue from './qr-action-buttons.vue';
import qrCardVue from './qr.card.vue';
import qrFormBillingVue from './qr.form.billing.vue';
import qrFormCreditorVue from './qr.form.creditor.vue';
import qrFormDebtorVue from './qr.form.debtor.vue';

export default {
    components: {
        'qr-form-creditor': qrFormCreditorVue,
        'qr-form-debtor'  : qrFormDebtorVue,
        'qr-form-billing' : qrFormBillingVue,
        'qr-card'         : qrCardVue,
        'qr-buttons'      : qrActionButtonsVue,
    },
    data() {
        return {
            

        }
    },
    mounted() {
        this.$store.commit('global/setLanguagesPlus');
        this.currency = 'CHF'

    },
    beforeDestroy(){
        this.$store.commit('global/setLanguages');
    },
    computed: {
        currency: {
            get() {
                return this.$store.state.QR.currency;
            },
            set(currency) {
                this.$store.commit("QR/setCurrency", currency);
            }
        },
    },
    methods: {
        validateForms() {
            this.$refs["form.creditor"].validate();
            this.$refs.qrFormDebtor.$refs.form.validate();
            this.$refs.qrBilling.$refs.form.validate();
            return (
                this.$refs["form.creditor"].validate()
                && this.$refs.qrFormDebtor.$refs.form.validate()
                && this.$refs.qrBilling.$refs.form.validate()
            )
        }
    }
}

</script>

<style>

</style>